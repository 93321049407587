import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineHome } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { BsGlobe2 } from 'react-icons/bs';
import { useState, useEffect, useRef } from 'react';
import logo from '../../assest/homeImage/aplLogo.webp';
import { Link } from 'react-router-dom';

import { IoClose } from "react-icons/io5";


function Navbar() {

    const [tog, setTog] = useState(false)
    const [showNav, setShowNav] = useState(true)

    const [dropdown, setDropdown] = useState({
        Products: false,
        Investor: false
    })

    const handleDropdowntoggle = (link) => {
        setDropdown({ ...dropdown, [link]: !dropdown[link] });
    }














    return (
        <>

            <div className='top-0 z-50 '>
                <div className='flex justify-between md:gap-[900px] items-center md:pt-[46px] pt-[23px] md:pb-[44px] pb-[22px] bg-[#002F32]/70'>

                    {/* hambuger menu */}
                    <div className='md:pl-[100px] pl-[20px]'>
                        <Link to={'/'}>  <img src={logo} className='md:w-[158px] w-[100px] md:h-[67px] h-[35px] ' /></Link>
                    </div>

                    <div className='flex  md:gap-[80px] gap-[20px] md:pl-[43px] pl-[5px]'>

                        {
                            tog ?

                                <div
                                    onClick={() => { setTog(!tog) }}
                                    className='md:right-[100px] right-[30px] md:top-[60px] top-[20px]  fixed flex items-center z-50  text-white'>

                                    <IoClose size={40} />

                                </div>
                                :
                                <div
                                    onClick={() => { setTog(!tog) }}
                                    className='flex items-center gap-[6px] z-50 md:pr-[100px] pr-[30px] w-[60px], h-[30px]'>
                                    <GiHamburgerMenu size={40} className='text-white ' />
                                </div>


                        }

                    </div>



                    {/* menu Items */}

                    <div className={`  brightness-75 z-10 top-0 right-0 object-fill bg-cover  md:h-full h-[500px] duration-700 fixed  ${tog ? 'md:right-[0px] right-[0px]' : 'top-[-1000px]'}`} >

                        <ul className=' flex md:text-[20px] text-[17px] text-white pl-[25px] md:w-[270px] w-[150px] bg-[#000000]/60 h-full'>

                            <div className='flex flex-col absolute top-[120px] items-start'>
                                <li className=' text-white brightness-100 font-roboto font-medium mt-[20px]'><Link to={'/'}> Home</Link></li>
                                <li className=' text-white brightness-100 font-roboto font-medium mt-[20px]'> <Link to={'/aboutus'}>  About Us </Link></li>
                                <li onClick={() => handleDropdowntoggle("Products")} className=' text-white brightness-100 font-roboto font-medium cursor-pointer mt-[20px]'> Products</li>
                                <li className=' text-white brightness-100 font-roboto font-medium mt-[20px] cursor-pointer'> <Link to={'/investor'}>  Investor </Link> </li>
                                <li className=' text-white brightness-100 font-roboto font-medium mt-[20px]'> <Link to={'/contactus'}> Contact Us</Link></li>
                            </div>

                            {/* Products dropdown  */}
                            <div className=''>
                                {
                                    dropdown.Products && (
                                        <ul className={`pt-[150px] md:left-[-138px] left-[-125px] p-6 w-fit  rounded-b-xl bg-[#FE6910]/90  z-10   object-fill bg-cover  duration-500 fixed  ${tog ? 'top-[0px]' : ''}`} >
                                            <li
                                                onClick={() => { setShowNav(false) }}
                                                className='text-white brightness-100 font-roboto font-medium mt-[20px]'> <Link to={'/rooftuff'}> Roof Tuff </Link> </li>
                                            <li
                                                className='text-white brightness-100 font-roboto font-medium mt-[20px]'> <Link to={'/lumetuff'}> Lume Tuff </Link> </li>
                                            <li
                                                className='text-white brightness-100 font-roboto font-medium mt-[20px]'> <Link to={'/rakshak'}> Rakshak </Link> </li>
                                            <li
                                                className='text-white brightness-100 font-roboto font-medium mt-[20px]'> <Link to={'/navrang'}> Navrang </Link> </li>
                                        </ul>
                                    )
                                }
                            </div>

                        </ul>


                    </div>
                </div >
            </div >

        </>
    )
}
export default Navbar;