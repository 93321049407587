

import Home from './component/Home';
// import AboutMain from './component/AboutMain';
import OtherMain from "./component/OtherMain";
import MainContactFile from "./component/MainContactFile";
import { Route, Routes } from 'react-router-dom';
import AboutPage from './component/AboutPage';
import Product1 from './component/productpage/Product1';
import Product2 from './component/productpage/Product2';
import Product3 from './component/productpage/Product3';
import Product4 from './component/productpage/Product4';
import Product5 from './component/productpage/Product5';
import Financial from './component/contactComponent/financial/Financial';
import General_Information from './component/contactComponent/generalInformation/General_Information';
import Downloads from './component/contactComponent/downloads/Downloads';
import Announcement from './component/contactComponent/announcements/Announcement';
import Presentation from './component/contactComponent/presentation/Presentation';
import Navbar from './component/homecomponent/Navbar';
import AnnualReport from './component/contactComponent/financial/AnnualReport';
import Annual_Return from './component/contactComponent/financial/Annual_Return';
import FinancialResult from './component/contactComponent/financial/financialResult/FinancialResult';
import StockExchange from './component/StockExchange';
import BoardMeeting from './component/contactComponent/announcements/BoardMeeting';
import OutcomePdf from './component/contactComponent/financial/OutcomePdf';
import DeetailOf_DT from './component/DeetailOf_DT';
import CraditRating from './component/CraditRating';
function App() {
  return (
    <>


      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/investor' element={<OtherMain />} />
        <Route path='/contactus' element={<MainContactFile />} />
        <Route path='/aboutus' element={<AboutPage />} />
        <Route path='/rakshak' element={<Product1 />} />
        <Route path='/lumetuff' element={<Product2 />} />
        <Route path='/rooftuff' element={<Product3 />} />
        <Route path='/navrang' element={<Product4 />} />
        <Route path='/product5' element={<Product5 />} />
        <Route path='/financial' element={<Financial />} />
        <Route path='/downloads' element={<Downloads />} />
        <Route path='/General_Information' element={<General_Information />} />
        <Route path='/announcement' element={<Announcement />} />
        <Route path='/presentation' element={<Presentation />} />
        <Route path='/annualReport' element={<AnnualReport />} />
        <Route path='/annualReturn' element={<Annual_Return />} />
        <Route path='/financialResult' element={<FinancialResult />} />
        <Route path='/stockexcahnge' element={<StockExchange />} />
        <Route path='/bordMeeting' element={<BoardMeeting />} />
        <Route path='/outcome' element={<OutcomePdf />} />
        <Route path='/deetai_dt' element={<DeetailOf_DT />} />
        <Route path='credit_Rating' element={<CraditRating />} />
      </Routes>







    </>
  );
}

export default App;
