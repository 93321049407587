import React from 'react'
import { BsDownload } from 'react-icons/bs';
import Navbar from './homecomponent/Navbar';
import banner from '../assest/contactImage/banner2.webp';
import Footer from './homecomponent/Footer';
import StockExchangepdf from '../assest/Website/stock exchange intimation/AGMproceddings30sept.pdf';
import pdflogo from '../assest/pdfLogo.jpg';
import StockExchangepdf2 from '../assest/Website/stock exchange intimation/Closure_of_trading_window_30_sept.pdf';
import StockExchangepdf3 from '../assest/Website/stock exchange intimation/Half yearly statement-Financials-STX intimation.pdf';
import StockExchangepdf4 from '../assest/Website/stock exchange intimation/Trading window closure-Dec 2023 - Signed.pdf';
import ClosureOftreadingWindowMarch20 from '../assest/Website/stock exchange intimation/Closure_of_Trading_Window _ March _ 2024.pdf';

const StockExchange = () => {
    return (
        <>

            <div className='max-w-[1920px] md:h-[620px] h-[300px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[110px] mt-[50px] gap-[16px]'>
                            <h1 className='pb-[30px] font-black  md:text-[70px] text-white text-center uppercase'>Financial Result
                            </h1>

                            {/* <h3 className='md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto  text-center'>Coming soon </h3> */}
                            <div className='w-[176px] h-[2px] bg-white '></div>

                        </div>

                    </div>
                </div>

            </div>

            <div className='flex flex-col mt-[50px] items-center md:gap-[33px] gap-[10px]'>

                <div className='border-b-2 border-gray-300 md:w-[800px] w-full md:h-[35px] h-[56px]  '>
                    <h3 className='text-black md:text-[17px] text-[15px] md:pl-0 pl-[20px] font-bold font-roboto uppercase'>Financial Year 2023</h3>
                </div>

                <div className=' md:w-[800px] w-full md:pl-0 pl-5   flex flex-col   gap-[20px] p-1'>

                    <div className='flex items-center gap-[70px]'>
                        <img src={pdflogo} className='' />
                        <h3 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto'><a href={ClosureOftreadingWindowMarch20}>Closure of trading window march 2024.pdf</a></h3>
                    </div>

                    <div className='flex items-center gap-[70px]'>
                        <img src={pdflogo} className='' />
                        <h3 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto'><a href={StockExchangepdf4}>Trading window closure-Dec 2023 - Signed.pdf</a></h3>
                    </div>




                    <div className='flex items-center gap-[70px]'>
                        <img src={pdflogo} className='' />
                        <h3 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto'><a href={StockExchangepdf3}>Half yearly statement-Financials-STX intimation.pdf</a></h3>
                    </div>


                    <div className='flex items-center gap-[70px]'>
                        <img src={pdflogo} className='' />
                        <h3 className='text-[14px] text-[#00ACB6]   font-roboto'><a href={StockExchangepdf}>AGM proceddings 30 sept.pdf.pdf</a></h3>
                    </div>

                    <div className='flex items-center gap-[70px]'>
                        <img src={pdflogo} className='' />
                        <h3 className='text-[14px] text-[#00ACB6]  font-roboto'><a href={StockExchangepdf2}>Closure_of_trading_window_30_sept.pdf</a></h3>
                    </div>







                </div>
            </div>

            <Footer />


        </>
    )
}

export default StockExchange