import React from 'react'
import banner from '../../../assest/contactImage/banner4.webp';
import Navbar from '../../homecomponent/Navbar';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';
import Footer from '../../homecomponent/Footer';
import { useState } from 'react';


import {
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
} from "tw-elements-react";

import Debenture from './Debenture';
import Grievance from './Grievance';
import Committees from './Committees';
import RelatedPartyTransactions from './RelatedPartyTransactions';
import Unpaid from './Unpaid';
import Corporate from './Corporate';
import ShereHolding from './ShereHolding';


const General_Information = () => {



    const [verticalActive, setVerticalActive] = useState("tab1");

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }
        setVerticalActive(value);
    };


    return (
        <>


            {/* Header */}
            <div className='max-w-[1920px] md:h-[500px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[30px] mt-[100px] md:gap-[16px] gap-[10px]'>
                            <div className='pb-[30px] font-black md:text-[60px] text-[30px] text-white text-center uppercase'>General Information
                            </div>

                            <h1 className='md:text-[24px] text-[18px] text-[#FFFFFF] font-semibold font-roboto md:text-normal text-center '>APL Apollo Tubes Limited is India’s largest Structural Steel Tubing Company with
                                an extremely strong local presenc</h1>
                            <div className='w-[176px] h-[2px] bg-white mt-[70px]'></div>

                        </div>

                    </div>
                </div>

            </div>




            <div className='flex md:flex-row flex-col my-[100px] md:pl-[50px] pl-[10px] md:pr-0 pr-[10px]'>

                <TETabs vertical className='md:w-[332px] w-full h-[400px] border border-[#BCBCBC]'>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab1")}
                        active={verticalActive === "tab1"}
                        className='text-[18px]  font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>Debenture Trustees
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab2")}
                        active={verticalActive === "tab2"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Share Holding Pattern
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab3")}
                        active={verticalActive === "tab3"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Grievance Management
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab4")}
                        active={verticalActive === "tab4"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Committees Composition
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab5")}
                        active={verticalActive === "tab5"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Related Party Transactions
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab6")}
                        active={verticalActive === "tab6"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Unpaid and Unclaimed Ammount
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab7")}
                        active={verticalActive === "tab7"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Corporate Governance
                    </TETabsItem>
                </TETabs>







                <TETabsContent className='mt-[20px]'>

                    <TETabsPane show={verticalActive === "tab1"}><Debenture /></TETabsPane>
                    <TETabsPane className='text-[40px] md:pl-[200px] pl-4 text-center' show={verticalActive === "tab2"}><ShereHolding /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab3"}><Grievance /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab4"}><Committees /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab5"}><RelatedPartyTransactions /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab6"}><Unpaid /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab7"}><Corporate /></TETabsPane>

                </TETabsContent>

            </div>


            <Footer />


        </>
    )
}

export default General_Information;