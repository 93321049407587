import React from 'react'
import banner from '../../../assest/contactImage/banner4.webp';
import Navbar from '../../homecomponent/Navbar';

const Committees = () => {
    return (

        <>

            <div className='flex  justify-center gap-[60px]'>

                <div className='flex flex-col md:gap-[14px] gap-[11px]'>

                    <div>
                        <h1 className='text-[#00ACB6] text-[24px] font-roboto font-bold'>APL APOLLO TUBES LIMITED</h1>
                        <p className='text-[#8A8A8A] text-[16px] font-roboto'>Composition Of Various Committees of Board of Directors</p>
                    </div>


                    <p className='text-[#F47E32] md:text-[15px] text-[13px] font-[600px] font-roboto '>A. Composition of Audit Committee</p>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] items-center flex justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Name of Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Category</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Designation</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Anil Kumar Bansal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Chairman</h1>

                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Abhilash Lal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>

                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Ms. Neeru Abrol</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>

                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Vinay Gupta</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Non-Executive Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <p className='text-[#F47E32] md:text-[15px] text-[13px] font-[600px] font-roboto mt-[20px]'>B. Composition of Nomination & Remuneration Committee</p>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] items-center flex justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Name of Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Category</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Designation</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Ms. Neeru Abrol</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Chairperson</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri V.S.Jain</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Vinay Gupta</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Non-Executive Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <p className='text-[#F47E32] md:text-[15px] text-[13px] font-[600px] font-roboto mt-[20px]'>C. Composition of Stakeholders Relationship Committee</p>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] items-center flex justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Name of Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Category</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Designation</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Anil Kumar Bansal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Chairperson</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri V.S.Jain</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Ashok K Gupta</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Non-Executive Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <p className='text-[#F47E32] md:text-[15px] text-[13px] font-[600px] font-roboto mt-[20px]'>D. Composition of Corporate Social Responsibility Committee</p>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] items-center flex justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Name of Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Category</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Designation</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Anil Kumar Bansal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Chairperson</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Abhilash Lal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Ashok K Gupta</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Non-Executive Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>


                    <p className='text-[#F47E32] md:text-[15px] text-[13px] font-[600px] font-roboto mt-[20px]'>E. Composition of Risk Management Committee</p>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] items-center flex justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Name of Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Category</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-bold font-roboto uppercase'>Designation</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Anil Kumar Bansal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Chairman</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Abhilash Lal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Chairperson</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Anil Kumar Bansal</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Ms. Neeru Abrol</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Independent Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] shadow-xl shadow-[#0000001A]  rounded-[5px] flex   items-center justify-around md:pl-[44px] '>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Shri Ashok K Gupta</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Non-Executive Director</h1>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[13px] font-[600px] font-lato'>Member</h1>
                    </div>

                </div>

            </div>


            {/* <AboutFooter /> */}



        </>
    )
}

export default Committees