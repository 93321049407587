import React from 'react'
import Investor from './otherComponent/Investor'

const OtherMain = () => {
    return (
        <div>

            <Investor />
            
        </div>
    )
}

export default OtherMain