import React from 'react'
import banner from '../../assest/contactImage/banner.webp';
import Navbar from '../homecomponent/Navbar';
import vecter from '../../assest/contactImage/vector.webp';
import { FaLocationDot } from 'react-icons/fa6';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { IoMdMail } from 'react-icons/io';
import { FaXTwitter } from 'react-icons/fa6';
import { BiLogoFacebook } from 'react-icons/bi';
import { FaLinkedinIn } from 'react-icons/fa';
import Footer from '../homecomponent/Footer';
import { Link } from 'react-router-dom'

import { useState } from 'react';

import AOS from 'aos';


const ConatctPage1 = () => {

    AOS.init({ duration: 900 });


    const [showSignUp, setShowSignUp] = useState(true);

    const toggleContent = () => {
        setShowSignUp(!showSignUp);
    };


    function handleSubmit(e) {

        window.location.reload()
        // e.preventDefault();
    }

    return (
        <>

            {/* header section */}

            <div className='max-w-[1920px] md:h-[500px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center mt-[30px] gap-[16px]'>
                            <h1 data-aos="zoom-in" className='pb-[30px] font-black font-roboto md:text-[80px] text-[40px] text-white text-center uppercase'>Contact us
                            </h1>

                            <h4 data-aos="zoom-in" className='md:text-[24px] text-[17px] text-[#FFFFFF]  font-roboto text-center'>
                                APL Apollo Building Products Pvt. Ltd. is India’s largest structural steel tube company with an extremely strong local presence.
                            </h4>
                            <div className=' md:block hidden w-[176px] h-[2px] bg-white mt-[70px]'></div>

                        </div>

                    </div>
                </div>

            </div>


            {/* Mid section */}

            <div
                className='max-w-[1900px] h-[500px]   mt-[300px] relative  md:block hidden  '>
                <div
                    style={{ backgroundImage: `url(${vecter})` }}
                    className=' w-full h-full bg-cover object-fill'>




                    <div className='absolute left-[185px] top-[-160px] w-1/3'>
                        <div>
                            <h4 className='text-[50px] font-roboto font-bold'>
                                <span data-aos="fade-right" className='text-[#F47E32]'>Connecting</span> <span data-aos="fade-up" className=''> World for
                                    Better Solving</span>
                            </h4>
                        </div>

                    </div>



                    <div

                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className=' absolute left-[185px] top-[120px] w-[400px] h-[320px] bg-white flex flex-col items-center  gap-[20px] p-[20px] shadow-2xl shadow-[#00000026] rounded-[10px]'>
                        <div>
                            <h4 data-aos="fade-up" className='text-[25px] text-[#F47E32] font-bold font-roboto'>PLANT OFFICE</h4>
                        </div>


                        <h4 data-aos="fade-up" className='text-[#606060] text-[18px] font-roboto font-medium'>Raipur</h4>

                        <div className='w-full'>
                            <p data-aos="fade-up" className='text-[15px] text-[#000000] font-roboto font-medium'>
                                Village - Rigni, Kesda, Tahsil - Simga, Distt. - Baloda Bazar - Bhatapara, Chhattsgarh – 493113</p>
                        </div>

                        <div>
                            <h4 data-aos="fade-up" className='text-[15px] font-roboto font-medium'>Tel: +91-120-4742700/701</h4>
                        </div>

                    </div>

                    <div
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"

                        className='absolute top-[120px] right-[500px] w-[400px] h-[320px] bg-white flex flex-col items-center  gap-[20px] p-[20px] shadow-xl shadow-[#00000026] rounded-[10px]'>
                        <div>
                            <h4 data-aos="fade-up" className='text-[25px] text-[#F47E32] font-bold font-roboto uppercase'>corporate OFFICE
                            </h4>
                        </div>


                        <h4 data-aos="fade-up" className='text-[#606060] text-[18px] font-roboto font-medium'>Noida </h4>


                        <p data-aos="fade-up" className='text-[15px] text-[#000000] font-roboto font-medium'>
                            SG Centre, Plot No. 37-C, Block-B, Sector-132, Noida, Distt. Gautam Budh Nagar, U.P.-201301
                        </p>



                        <h4 data-aos="fade-up" className='text-[15px] font-roboto font-medium'>Tel: +91 - 120 6918000</h4>

                        {/* <h4 data-aos="fade-up" className='text-[15px] font-roboto font-medium'>Whatsapp: +91 9205166612</h4> */}

                    </div>

                    <div

                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className='absolute top-[-65px] right-[70px] w-[400px] h-[500px] bg-[#003639] rounded flex flex-col  justify-center items-center gap-[25px] p-[27px]'>

                        <h4 data-aos="fade-up" className='text-[25px] font-roboto font-medium text-[#FFFFFF]'>
                            REGISTERED OFFICE
                        </h4>

                        <div data-aos="fade-up" className='flex gap-[20px] justify-center items-center '>
                            <FaLocationDot size={20} className='text-[#B0B0B0]' />

                            <div className='flex flex-col justify-between '>
                                <h4 className='text-[15px] font-roboto font-medium text-[#FFFFFF]'>DELHI</h4>
                                <p className='text-[#FFFFFF] font-roboto font-medium text-[15px]'>37, Hargobind Enclave Vikas Marg New Delhi 110092 (India)</p>
                            </div>

                        </div>

                        <div data-aos="fade-up" className='flex gap-[20px] mr-[150px]'>
                            <BsFillTelephoneFill size={20} className='text-[#B0B0B0]' />
                            <h4 className='text-[#FFFFFF] text-[15px] font-medium font-roboto'>Tel: +91-11-22373437</h4>
                        </div>

                        <div data-aos="fade-up" className='flex gap-[20px] mr-[150px]'>
                            <IoMdMail size={20} className='text-[#B0B0B0]' />
                            <h4 className='text-[#FFFFFF] text-[15px] font-medium font-roboto'>info@aplapolllo.com</h4>

                        </div>

                        {/* <div data-aos="fade-up" className='text-[#B0B0B0] flex  gap-[50px]'>
                            <FaXTwitter size={30} />
                            <BiLogoFacebook size={30} />
                            <FaLinkedinIn size={30} />

                        </div> */}

                    </div>
                </div>

            </div>


            {/* for mobile view */}

            <div className='md:hidden block '>

                <div className='flex flex-col justify-center items-center space-y-7'>

                    <div className=' pl-[10px]'>
                        <div data-aos="zoom-out-up" className='flex flex-col gap-3'>
                            <h4 className='md:text-[50px] text-[30px] font-roboto font-bold'>
                                <span className='text-[#F47E32]'>Connecting</span> World for
                                Better Solving
                            </h4>
                        </div>

                    </div>



                    <div

                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className=' w-[360px] h-[270px] bg-white flex flex-col items-center text-center  gap-[20px] p-[20px] shadow-2xl shadow-[#00000026] rounded-[10px]'>
                        <div>
                            <h4 data-aos="fade-up" className='text-[25px] text-[#F47E32] font-bold font-roboto'>CORPORATE OFFICE</h4>
                        </div>


                        <h4 data-aos="fade-up" className='text-[#606060] text-[18px] font-roboto font-medium'>Raipur</h4>

                        <div className='w-full'>
                            <p data-aos="fade-up" className='text-[14px] text-[#000000] font-roboto font-medium'>
                                Village - Rigni, Kesda, Tahsil - Simga, Distt. - Baloda Bazar - Bhatapara, Chhattsgarh – 493113                            </p>
                        </div>

                        <div>
                            <h4 data-aos="fade-up" className='text-[18px] font-roboto font-medium'>Tel: +91-120-4742700/701</h4>
                        </div>

                    </div>




                    <div
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className=' w-[360px] h-[270px] bg-white flex flex-col items-center  text-center gap-[20px] p-[20px] shadow-xl shadow-[#00000026] rounded-[10px]'>
                        <div>
                            <h4 data-aos="fade-up" className='text-[25px] text-[#F47E32] font-bold font-roboto'>HEAD OFFICE
                            </h4>
                        </div>


                        <h4 data-aos="fade-up" className='text-[#606060] text-[15px] font-roboto font-medium'>Kaushambi</h4>


                        <p data-aos="fade-up" className='text-[10px] text-[#000000] font-roboto font-medium'>
                            36, Kaushambi Near Anand Vihar Terminal Ghaziabad Uttar Pradesh 201010 (India)
                        </p>



                        <h4 data-aos="fade-up" className='text-[10px] font-roboto font-medium'>Tel: +91-120-4041400</h4>

                        <h4 data-aos="fade-up" className='text-[10px] font-roboto font-medium'>Whatsapp: +91 9205166612</h4>

                    </div>



                    <div

                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className=' w-[360px] h-[500px] bg-[#003639] rounded flex flex-col items-start gap-[25px] p-[27px]'>

                        <h4 data-aos="fade-up" className='text-[25px] pl-[30px] font-roboto font-medium text-[#FFFFFF]'>
                            REGISTERED OFFICE
                        </h4>

                        <div data-aos="fade-up" className='flex gap-[20px] justify-center items-center border-b border-[#727272]'>
                            <FaLocationDot size={40} className='text-[#B0B0B0]' />

                            <div className='flex flex-col justify-between '>
                                <h4 className='text-[18px] font-roboto font-medium text-[#FFFFFF]'>DELHI</h4>
                                <p className='text-[#FFFFFF] font-roboto font-medium text-[18px]'>37, Hargobind Enclave Vikas Marg New Delhi 110092 (India)</p>
                            </div>

                        </div>

                        <div data-aos="fade-up" className='flex gap-[20px]'>
                            <BsFillTelephoneFill size={30} className='text-[#B0B0B0]' />
                            <h4 className='text-[#FFFFFF] text-[18px] font-medium font-roboto'>Tel: +91-11-22373437</h4>
                        </div>

                        <div data-aos="fade-up" className='flex gap-[20px]'>
                            <IoMdMail size={30} className='text-[#B0B0B0]' />
                            <h4 className='text-[#FFFFFF] text-[18px] font-medium font-roboto'>info@aplapolllo.com</h4>

                        </div>

                        <div data-aos="fade-up" className='text-[#B0B0B0] flex  gap-[50px]'>
                            <FaXTwitter size={30} />
                            <BiLogoFacebook size={30} />
                            <FaLinkedinIn size={30} />

                        </div>

                    </div>


                </div>

            </div>




            {/* Get in touch */}

            {/* <div className='md:mt-[100px] mt-[50px] md:pl-0 pl-[10px] md:pr-0 pr-[10px]'>
                <div>
                    <h4 data-aos="zoom-out-up" className='text-[#F47E32] md:text-[80px] text-[30px] font-bold font-roboto text-center'>Get in <spna className='	text-[#003639]'> touch </spna> </h4>
                </div>

                <div className='flex items-center justify-center mt-[50px]'>
                    <form id="contactForm" className='w-[850px] h-[500px]'>
                        <div class="mb-6">
                            <div class="mx-0 mb-1 sm:mb-4">
                                <div data-aos="zoom-out-up" class="mx-0 mb-1 sm:mb-4">
                                    <label for="name" class="pb-1  uppercase tracking-wider font-roboto">Full Name</label>
                                    <input type="text" id="name" autocomplete="given-name" placeholder="Your name" className="mb-2 w-full rounded-md border border-gray-400 hover:border-[#16100c] hover:p-3  py-2 pl-2 pr-4 shadow-md sm:mb-0 outline-none hover:bg-[#352216]/10 duration-500" name="name" />
                                </div>

                                <div data-aos="zoom-out-up"
                                    data-aos-duration="1000"
                                    class="mx-0 mb-1 sm:mb-4">
                                    <label for="name" class="pb-1  uppercase tracking-wider font-roboto">Number</label>
                                    <input type="text" id="name" autocomplete="given-name" placeholder="Number" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md  sm:mb-0 outline-none hover:p-3  hover:bg-[#352216]/10 duration-500" name="name" />
                                </div>

                                <div data-aos="zoom-out-up"
                                    data-aos-duration="1100"
                                    class="mx-0 mb-1 sm:mb-4">
                                    <label for="email" class="pb-1  uppercase tracking-wider font-roboto">Email</label>
                                    <input type="email" id="email" autocomplete="email" placeholder="Your email address" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md  sm:mb-0 outline-none hover:p-3  hover:bg-[#352216]/10 duration-500" name="email" />
                                </div>
                            </div>
                            <div data-aos="zoom-out-up"
                                data-aos-duration="1200"
                                class="mx-0 mb-1 sm:mb-4">
                                <label for="textarea" class="pb-1  uppercase tracking-wider"></label><textarea id="textarea" name="textarea" cols="30" rows="5" placeholder="Write your message..." className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0 hover:bg-[#352216]/10 duration-500"></textarea>
                            </div>
                        </div>

                        <div className='flex mt-[10px] justify-center'>
                            <Link >
                                <div
                                    data-aos="zoom-out-up"
                                    data-aos-duration="1300"
                                    className='w-[203px] h-[58px] hover hover:bg-[#F47E32] hover:text-white duration-300
                     border-2 border-[#F47E32] text-center flex justify-center text-[#F47E32] text-[18px] font-roboto font-bold'>
                                    <button type='submit' onSubmit={handleSubmit}> Submit</button>
                                </div>
                            </Link>
                        </div>

                    </form>
                </div>

            </div> */}

            <Footer />

        </>




    )
}

export default ConatctPage1