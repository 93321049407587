import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import banner from '../../assest/contactImage/banner4.webp';
import Navbar from '../homecomponent/Navbar';
import swa2 from '../../assest/productImage/swa2.webp';
import pa4 from '../../assest/contactImage/pa4.webp';
import pa5 from '../../assest/contactImage/pa5.webp';
import pa6 from '../../assest/contactImage/pa6.webp';

import rakshak from '../../assest/productImage/APL RAKSHAK/Broucher.png';
import icon1 from '../../assest/productImage/APL RAKSHAK/bg_icon1.png';
import icon2 from '../../assest/productImage/APL RAKSHAK/bg_icon2.png';
import icon3 from '../../assest/productImage/APL RAKSHAK/bg_icon3.png';
import icon4 from '../../assest/productImage/APL RAKSHAK/bg_icon4.png';
import icon5 from '../../assest/productImage/APL RAKSHAK/bg_icon5.png';
import icon6 from '../../assest/productImage/APL RAKSHAK/bg_icon6.png';
import icon7 from '../../assest/productImage/APL RAKSHAK/bg_icon7.png';



import bg from '../../assest/productImage/APL RAKSHAK/bg_left.webp';
import product from '../../assest/productImage/APL RAKSHAK/4images.png';

import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Footer from '../homecomponent/Footer';
import NavButtons from '../../component/NavButtons';


const Product1 = () => {
    return (
        <>

            <div className='max-w-[1620px] md:h-[720px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div data-aos="zoom-in"
                            data-aos-duration="1000"
                            className=' bg-[#00ACB6]/70 md:ml-[250px] md:mr-[250px]'>
                            <div className='flex flex-col justify-center items-center mt-[130px] gap-[10px] p-[30px] '>
                                <div className='md:pb-[30px] font-black md:text-[60px] text-[30px] text-white text-center uppercase'>Apollo Exclusive
                                </div>

                                <h4 className='text-[24px] text-[#FFFFFF] font-semibold font-lato text-justify'>Apollo Rakshak</h4>
                                <div className='w-[176px] h-[2px] bg-white mt-[70px]'></div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/*  */}





            {/* for mobile */}
            <div className='md:hidden block   flex-col pl-[10px] '>
                <div className='mt-[10px] pl-[10px] pr-[10px]'>
                    <h1 className='text-[35px] font-roboto font-extrabold md:text-start text-center'>APL Apollo Rakshak</h1>
                </div>
            </div>



            <div className='flex md:flex-row flex-col md:gap-[80px] gap-[11px] md:mt-[72px] mt-[0px] md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px]'>

                <div className='md:w-[45%] w-full md:h-[601px] h-[300px] shadow-2xl shadow-[#00000026] md:mt-[100px] mt-4 flex justify-center items-center '>
                    <img src={rakshak} alt="APL Rakshak" className='w-full h-full' />

                </div>


                <div className=' md:w-[50%] flex flex-col gap-[14px] md:mt-[40px]  '>

                    <div data-aos-duration='1000' className='md:block hidden'>
                        <h4 className='text-[55px] font-roboto font-extrabold '>APL Apollo Rakshak</h4>
                        <i className='text-[25px] text-[#4E4E4E] font-roboto font-semibold'>The ultimate protection to shelter your dreams</i>
                    </div>

                    <div data-aos="fade-down" data-aos-duration='1000' >
                        <p className=' text-[#4E4E4E] md:text-[20px] text-[17px] font-roboto'>
                            APL Apollo Rashak sheets have a covering of zinc and aluminum (Al-Zn), which offers
                            exceptional corrosion protection and extends their lifespan for residential roofs and industrial
                            Sheds, Warehouse Sheds, and Commercial Buildings. It offers a two-times better life than ordinary
                            GC Sheet under the same exposure with a better return on investment. These sheets provide
                            galvanic protection to bare edges, similar to zinc-coated steel.
                        </p>
                    </div>
                    {/* Specifications  */}
                    <div className='md:pr-[80px]'>
                        <h4 className='md:text-[25px] text-[20px] text-[#4E4E4E] md:text-left text-center font-roboto font-semibold'>PRODUCT <span className='text-[#FE6910] '> SPECIFICATION</span></h4>
                        <div className='mt-[20px] space-y-2 '>

                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Width</h4>
                                <h4>900mm, 1000mm, 1320mm </h4>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Thickness</h4>
                                <h4>0.20mm - 0.60mm</h4>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Anti-Corrosion Coating</h4>

                                <h4>Min 150 gsm (Alu-Zinc)</h4>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Strength</h4>

                                <h4>Min. 550 MPa</h4>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Length</h4>

                                <h4>8,10,12,14,16,18,20 ft.</h4>
                            </div>

                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Metallic Coating</h4>

                                <h4>A 70 & AZ 150</h4>
                            </div>

                            <div className='flex md:text-[19px] text-[15px] justify-between font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <h4>Standards</h4>

                                <h4>ASTM A792, IS 277, IS: 15961</h4>
                            </div>

                        </div>


                    </div>

                </div>

            </div >

            {/* Key product features */}

            <div className='max-w-[1420px] h-fit md:block hidden  ml-[60px] relative'>

                <div style={{ backgroundImage: `url(${bg})` }}
                    className='w-full h-full bg-cover object-fill '
                >

                    <div className='absolute right-0 bottom-[-120px]'>

                        <img src={product} alt="APL Rakshak" className='w-[500px] h-[500px]' />

                    </div>

                    <div className='pt-[100px] md:mt-[80px]  md:pl-[30px]  md:pr-[50px] pr-[10px] flex flex-col md:gap-[30px] gap-[10px] pb-[250px] '>
                        <div>
                            <h4 className='md:pl-[25px] uppercase text-[55px]  text-black font-roboto font-semibold'>Key Product Features</h4>
                        </div>

                        <div className='flex flex-col gap-7 '>

                            <div className='flex gap-[120px]'>
                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center '>
                                    <div>
                                        <img src={icon1} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Better Return<br className='md:block hidden' /> on Investment</h4>
                                </div>

                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center '>
                                    <div>
                                        <img src={icon2} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Two times better
                                        life<br className='md:block hidden' /> than ordinary
                                        GC Sheet</h4>
                                </div>

                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center'>
                                    <div>
                                        <img src={icon3} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Provides galvanic<br className='md:block hidden' />

                                        protection from bare edges

                                    </h4>
                                </div>

                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center'>
                                    <div>
                                        <img src={icon4} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Greater resistance<br className='md:block hidden' />
                                        to humidity</h4>
                                </div>

                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center'>
                                    <div>
                                        <img src={icon5} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Less affected by<br className='md:block hidden' />
                                        stagnant water</h4>
                                </div>

                            </div>

                            <div className='flex  gap-[120px]'>



                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center'>
                                    <div>
                                        <img src={icon6} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Environment<br />
                                        friendly</h4>
                                </div>

                                <div className='flex flex-col  md:gap-0 gap-[30px] items-center'>
                                    <div>
                                        <img src={icon7} alt="APL Rakshak" className='w-[120px] h-[120px]' />
                                    </div>
                                    <h4 className='text-center text-[19px] font-roboto font-semibold text-[#4E4E4E]'>Less maintenance <br className='md:block hidden' />
                                        and exceptional
                                        value.</h4>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>




            {/* for mobile responsive  */}
            <div className='md:hidden block md:mt-[80px] mt-[40px] md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px] flex-col md:gap-[30px] gap-[10px]'>
                <div>
                    <h4 className='md:pl-[25px] uppercase md:text-[40px] text-[15px] text-[#FE6910] font-roboto font-semibold'>Key Product Features</h4>
                </div>

                <div className='flex md:flex-row flex-col justify-between'>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center '>
                        <div className='w-[25%] flex items-center justify-center'>
                            <img src={icon1} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className='w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Better Return<br className='md:block hidden' /> on Investment</h4>
                    </div>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center '>
                        <div className='w-[25%] flex items-center justify-center' >
                            <img src={icon2} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className='w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Two times better
                            life than ordinary
                            GC Sheet</h4>
                    </div>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center'>
                        <div className='w-[25%] flex items-center justify-center'>
                            <img src={icon3} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className='w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Provides galvanic<br className='md:block hidden' />
                            protection to bare
                            edges</h4>
                    </div>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center'>
                        <div className='w-[25%] flex items-center justify-center'>
                            <img src={icon4} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className='w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Greater resistance<br className='md:block hidden' />
                            to humidity</h4>
                    </div>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center'>
                        <div className='w-[25%] flex items-center justify-center'>
                            <img src={icon5} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className='w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Less affected by
                            stagnant water</h4>
                    </div>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center'>
                        <div className='w-[25%] flex items-center justify-center'>
                            <img src={icon6} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className='w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Environment
                            friendly</h4>
                    </div>

                    <div className='flex md:flex-col flex-row md:gap-0 gap-[30px] items-center'>
                        <div className='w-[25%] flex items-center justify-center'>
                            <img src={icon7} alt="APL Rakshak" className='w-[100px]' />
                        </div>
                        <h4 className=' w-[75%] text-center text-[14px] font-roboto font-semibold text-[#4E4E4E]'>Less maintenance
                            and exceptional
                            value</h4>
                    </div>

                </div>

            </div>





            {/* Product Application carousoles */}

            {/* Owl Crousol */}
            <div className='md:block hidden'>
                <div className='mt-[80px]'>
                    <h4 className='text-[#000000] md:text-[55px] text-[25px] font-roboto text-center font-extrabold'>
                        PRODUCT <span className='text-[#F47E32]'> APPLICATIONS</span>
                    </h4>
                </div>


                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={60}
                    slidesPerView={4}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className='w-[1400px] h-[300px]  mt-[76px]'

                >



                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="APL Rakshak" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* for  mobile responsive */}

            <div className='md:hidden block pl-[10px] pr-[10px]'>
                <div className='mt-[47px]'>
                    <h4 className='font-roboto md:text-[50px] text-[25px] font-extrabold'>
                        PRODUCT APPLICATIONS
                    </h4>
                </div>


                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={10}
                    slidesPerView={3}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        type: 'fraction',
                    }}

                    scrollbar={{ draggable: true }}
                    className='max-w-[1400px] h-fit  mt-[32px]'

                >

                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="APL Rakshak" className='w-full h-full rounded' />
                        </SwiperSlide>

                    </div>
                    <NavButtons />
                </Swiper>
            </div>

            {/* Structure careousel */}
            <div className='flex md:flex-row flex-col justify-between md:mt-[100px] my-[50px]'>

                <div className='flex flex-col md:pl-[50px] pl-[10px]  gap-[10px]'>

                    <h4 className='font-roboto md:text-[55px] text-[25px] font-extrabold'>STRUCTURAL IN OUR WORLD</h4>

                    <div className=''>


                        <button type='button'
                            className='w-[258px] h-[56px]  inline-flex   bg-[#FE6910]/100 hover:bg-gray-900 duration-300 justify-evenly items-center md:mt-[56px] mt-[20px]'>
                            <div className='gap-[9px]    inline-flex items-center justify-center   font-bold text-white transition-all duration-200    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FE6910]'>
                                <p className='text-[17px] font-roboto font-medium text-white '>DOWNLOAD BROCHURE</p>
                                <BsBoxArrowInUpRight size={30} className='text-white ' />
                            </div>
                        </button>
                    </div>
                </div>



                <div className='md:mt-[2px] mt-[30px] md:pl-[0px] pl-[10px]  md:pr-0 pr-[10px]'>
                    <Swiper
                        slidesPerView={2}
                        centeredSlides={true}
                        spaceBetween={30}
                        scrollbar={{ draggable: true }}
                        pagination={{
                            type: 'fraction',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination, Autoplay, Navigation, Scrollbar]}
                        className='md:w-[900px] w-full md:h-[350px] h-fit'
                    >
                        <div >

                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="APL Rakshak" />
                            </SwiperSlide>



                        </div>

                        <div className='flex justify-center mt-[10px]'>
                            <NavButtons />
                        </div>

                    </Swiper>

                </div>

            </div>



            <Footer />

        </>
    )
}

export default Product1;