import React from 'react'
import { BsDot } from 'react-icons/bs';

import Footer from './homecomponent/Footer';

import leader1 from '../assest/aboutImage/leader1.webp';
import leader2 from '../assest/aboutImage/leader2.webp';
import leader3 from '../assest/aboutImage/leader3.webp';
import leader4 from '../assest/aboutImage/leader4.webp';
import leader5 from '../assest/aboutImage/leader5.webp';
import leader6 from '../assest/aboutImage/leader6.webp';
import leader7 from '../assest/aboutImage/leader7.webp';
import leader8 from '../assest/aboutImage/leader8.webp';


import aboutheaderImage from '../assest/aboutImage/aboutbanner.webp';
import Navbar from './homecomponent/Navbar';
import banner from '../assest/aboutImage/tubesBanner.webp';
import mtlogo from '../assest/aboutImage/mtLogo.png';
import weblogo from '../assest/aboutImage/webLogo.png';
import unitlogo from '../assest/aboutImage/unitlogo.png';

import elt1 from '../assest/aboutImage/elt1.webp'
import elt2 from '../assest/aboutImage/elt2.webp'
import elt4 from '../assest/aboutImage/elt4.webp'
import elt5 from '../assest/aboutImage/elt5.webp'
import elt6 from '../assest/aboutImage/elt6.webp'
import elt7 from '../assest/aboutImage/elt7.webp'

import rohanGupta from '../assest/aboutImage/Rohan gupta.webp';


import AOS from 'aos';


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';




const AboutPage = () => {

    AOS.init({ duration: 900 });


    return (
        <>
            {/* Header */}

            <div className='max-w-full md:h-[500px] h-[450px]'>
                <div style={{ backgroundImage: `url(${aboutheaderImage})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>

                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[30px] mt-[80px] md:gap-[16px] '>
                            <div data-aos="zoom-in" className='pb-[20px] font-black md:text-[80px] text-[35px] text-white text-center'>ABOUT US
                            </div>

                            <h4 data-aos="zoom-in" className='md:text-[24px] text-[14px] md:p-0 p-4 text-[#FFFFFF] font-medium font-roboto tracking-wide text-center'>APL Apollo Building Products Pvt Ltd. is India’s largest Structural Steel Tubes <br /> Company with
                                an  extremely strong local presence</h4>
                            <div className='w-[176px] h-[2px] bg-white md:mt-[70px] mt-[5px]'></div>

                        </div>


                    </div>
                </div>
            </div>

            {/* APL Apolo tubes */}

            <div className='flex flex-col md:mt-[40px] mt-[10px] md:gap-[50px] gap-[20px]'>

                <div

                    data-aos-duration="1200"
                    className=' flex  pl-[10px] justify-center'>
                    <h1 className='md:text-[55px] text-[25px]  text-center md:font-normal font-semibold text-[#000000] font-roboto  '>
                        APL Apollo Building Products Pvt Ltd.
                    </h1>
                </div>

                <div

                    className='flex md:flex-row flex-col  justify-center md:gap-5 gap-2 md:pl-0 pl-[10px] md:pr-0 pr-[10px]'>

                    <div className='flex flex-col md:gap-2 gap-[10px] md:mt-[40px] mt-2 md:text-left text-center'>
                        <h4 className='md:text-[30px] text-[25px] font-roboto font-medium'>Project Commencement</h4>
                        <p className='text-[#7A7A7A] font-roboto md:text-[20px] text-[17px] tracking-wide '>The project started in FY 20-21, APL Apollo Building Products Pvt Ltd.
                            (ABPL) <br className='md:block hidden' /> a wholly owned subsidiary of APL Apollo Tubes Ltd. (APL) at Village  Simga, <br className='md:block hidden' />
                            Baloda Bazaar district in Chhattisgarh (almost 50 Km from Raipur Airport).
                        </p>
                    </div>

                    <div className='flex flex-col md:gap-2 gap-1 md:pl-0 pl-[10px] '>
                        <h4 className='md:text-[30px] text-[25px] font-roboto font-medium'>Product Focus</h4>

                        <div className='text-[#7A7A7A] md:text-[20px] text-[17px] font-medium  font-roboto items-center'>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>India’s 1st 3.0 to 20.0 mm HR Slitting Line.</p>
                            </div>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>1000 x 1000 mm Structure Tube 1st in India and 2nd Globally.</p>
                            </div>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>500 x 500 mm Structure Tube Section 1st in India.</p>
                            </div>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>1st Thicker Colour Coating Line from 1.60mm to 3.00 mm in India.</p>
                            </div>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>World 1st Colour Coated Structure Tubes.</p>
                            </div>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>India’s 1st Thicker non ox narrow galvanized up to 3.2mm to 3.2mm.</p>
                            </div>
                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>Environment Friendly - Zero Discharge Plant.</p>
                            </div>

                            <div className='flex items-center'>
                                <BsDot className='text-black md:text-[40px] text-[20px] md:block hidden' />
                                <p>Acid Regeneration Plant.</p>
                            </div>

                        </div>
                    </div>




                </div>

            </div>

            <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"

                className='md:block hidden'>
                <div className='max-w-[1520px] md:h-[485px] h-[300px] mt-[27px] relative'>
                    <div style={{ backgroundImage: `url(${banner})` }}
                        className='w-full h-full bg-cover object-fill'
                    >

                        <div className=''>

                            <div

                                className='bg-white w-[1300px] h-[300px] rounded-[10px] absolute bottom-[-200px] left-[100px] shadow-xl shadow-[#00000040]'>

                                <div data-aos="zoom-out-down" className='flex items-center pl-[100px] gap-[150px] pt-[59px] '>
                                    <div className='flex flex-col justify-center items-center gap-[18.5px]'>
                                        <img src={mtlogo} alt="leader image" className='w-[44px] h-[39px]' />
                                        <h4 className='text-[28px] font-roboto font-bold'>3.6 MT</h4>
                                        <p className='text-[24px] font-medium  text-[#838383] font-roboto
                                    '>Structural steeltube</p>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-[18.5px]'>
                                        <img src={weblogo} alt="leader image" className='w-[41px] h-[41px]' />
                                        <h4 className='text-[28px] font-roboto font-bold'>20</h4>
                                        <p className='text-[24px] font-medium  text-[#838383] font-roboto
                                    '>Countries across the Globe</p>
                                    </div>
                                </div>

                                <div className='pt-[70px] w-[450px] h-[340px] shadow-xl shadow-[#00000040] bg-white rounded-[10px] absolute right-0 top-[-25px]'>

                                    <div data-aos="zoom-out-down" className='flex flex-col justify-center items-center gap-[18.5px]'>
                                        <img src={unitlogo} alt="leader image" className='w-[56px] h-[56x]' />
                                        <h4 className='text-[28px] font-roboto font-bold'>11</h4>
                                        <p className='text-[24px] font-medium  text-[#838383] font-roboto
                                    '>Manufacturing units</p>
                                    </div>

                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>

            {/* for mobile  */}

            <div className='md:hidden block pl-[20px] pr-[20px] space-y-5 mt-[20px]'>

                <div

                    className='flex justify-between  items-center gap-[35px]'>
                    <div className='w-[20%]'>
                        <img src={mtlogo} alt="leader image" className='w-[36px] h-[36px]' />
                    </div>
                    <h4 className='text-[17px] font-roboto font-bold w-[20%]'>3.6 MT</h4>
                    <p className='w-[50%] md:text-[24px] text-[14px] text-end font-medium  text-[#838383] font-roboto  '>Structural steeltube</p>
                </div>


                <div
                    className='flex justify-between  items-center gap-[35px]'>
                    <div className='w-[20%]'>
                        <img src={weblogo} alt="leader image" className='w-[36px] h-[36px]' />
                    </div>
                    <h4 className='text-[17px] font-roboto font-bold w-[20%]'>20</h4>
                    <p className='w-[50%] md:text-[24px] text-end text-[14px] font-medium  text-[#838383] font-roboto  '>Countries across the Globe</p>
                </div>

                <div
                    className='flex justify-between  items-center gap-[35px]'>
                    <div className='w-[20%]'>
                        <img src={unitlogo} alt="leader image" className='w-[36px] h-[36x]' />
                    </div>
                    <h4 className='text-[17px] font-roboto font-bold w-[20%]'>11</h4>
                    <p className=' w-[50%] md:text-[24px] text-end text-[14px] font-medium  text-[#838383] font-roboto  '>Manufacturing units</p>
                </div>

            </div>




            {/* Our Leaders */}
            <div className=' md:block hidden  md:mt-[300px] mt-[30px]'>
                <div>
                    <div className='text-center flex flex-col items-center  gap-[10px]'>
                        <h4 className='md:text-[55px] text-[25px] text-[#000000] font-roboto font-bold  '>Our Leaders</h4>
                        <p className='md:text-[25px] text-[15px] text-[#525252] font-bold font-roboto'>Board of Directors</p>
                    </div>
                </div>



                <div className=' flex flex-wrap gap-6 justify-center  md:mt-[50px] mt-[30px] '>

                    <div className='relative flex flex-col w-fit h-[370px] p-1 items-center  justify-between  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                        <img src={leader1} alt="leader image" className='w-[257px] h-[280.34px]' />
                        <div className='flex flex-col items-center  pb-2 '>
                            <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto '>Mr. Sanjay Gupta</h4>
                            <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'> Director</h2>
                        </div>

                    </div>

                    <div className='relative flex flex-col w-fit h-[370px] p-1 items-center justify-between md:pt-0 pt-[20px]  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                        <img src={leader2} alt="leader image" className='w-[257px] h-[280.34px]' />
                        <div className='flex flex-col items-center pb-2'>
                            <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Vinay Gupta</h4>
                            <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Director</h2>
                        </div>

                    </div>


                    <div className='flex flex-col items-center w-fit h-[370px] justify-between p-1 md:pt-0 pt-[20px]  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                        <img src={rohanGupta} alt="leader image" className='w-[257px] h-[280.34px]' />
                        <div className='flex flex-col items-center pb-2'>
                            <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Rohan Gupta</h4>
                            <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Whole Time Director</h2>
                        </div>

                    </div>




                    <div className='flex flex-col items-center w-fit h-[370px] justify-between p-1 md:pt-0 pt-[20px]  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                        <img src={elt1} alt="leader image" className='w-[257px] h-[280.34px]' />
                        <div className='flex flex-col items-center  pb-2'>
                            <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Deepak Goyal</h4>
                            <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Director</h2>
                        </div>

                    </div>

                    <div className=' flex flex-col items-center w-fit h-[370px] justify-between p-1 md:pt-0 pt-[20px]  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                        <img src={elt2} alt="leader image" className='w-[257px] h-[280.34px]' />
                        <div className='flex flex-col items-center text-center pb-2'>
                            <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Rahul Gupta</h4>
                            <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Managing Director</h2>
                        </div>
                    </div>




                </div>
            </div>

            {/*  oure leaders mobile responsive */}


            <div className='md:hidden flex flex-col items-center justify-center   ml-3 mr-3 md:mt-[300px] mt-[30px]'>
                <div>
                    <div className='text-center flex flex-col items-center  gap-[10px]'>
                        <h4 className='md:text-[55px] text-[25px] text-[#000000] font-roboto font-bold  '>Our Leaders</h4>
                        <p className='md:text-[25px] text-[15px] text-[#525252] font-bold font-roboto'>Board of Directors</p>
                    </div>
                </div>



                <div className=' w-[300px]  flex justify-center items-center mt-[10px] '>
                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        autoplay={{ delay: 2000 }}
                        modules={[Autoplay]}
                    >

                        <SwiperSlide>
                            <div className='relative flex gap-2 flex-col w-fit h-fit p-1 items-center  justify-between  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                                <img src={leader1} alt="leader image" className='w-[300px] h-[330.34px]' />
                                <div className='flex flex-col items-center  pb-2 '>
                                    <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto '>Mr. Sanjay Gupta</h4>
                                    <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'> Director</h2>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='relative flex gap-2 flex-col w-fit h-fit p-1 items-center justify-between   bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                                <img src={leader2} alt="leader image" className='w-[300px] h-[330.34px]' />
                                <div className='flex flex-col items-center pb-2'>
                                    <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Vinay Gupta</h4>
                                    <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Director</h2>
                                </div>

                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col gap-2 items-center w-fit h-fit justify-between p-1   bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                                <img src={rohanGupta} alt="leader image" className='w-[300px] h-[330.34px]' />
                                <div className='flex flex-col items-center pb-2'>
                                    <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Rohan Gupta</h4>
                                    <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Whole Time Director</h2>
                                </div>

                            </div>
                        </SwiperSlide>



                        <SwiperSlide>
                            <div className='flex flex-col gap-2 items-center w-fit h-fit justify-between p-1  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                                <img src={elt1} alt="leader image" className='w-[300px] h-[330.34px]' />
                                <div className='flex flex-col items-center  pb-2'>
                                    <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Deepak Goyal</h4>
                                    <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Director</h2>
                                </div>

                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className=' flex flex-col gap-2 items-center w-fit h-fit justify-between p-1  bg-[#fbfbfb] text-center shadow-[0_2px_10px_0_rgba(0,0,0,0.14)] dark:bg-neutral-600'>
                                <img src={elt2} alt="leader image" className='w-[300px] h-[330.34px]' />
                                <div className='flex flex-col items-center text-center pb-2'>
                                    <h4 className='md:text-[25px] text-[17px] text-[#000000] font-bold font-roboto'>Mr. Rahul Gupta</h4>
                                    <h2 className='text-[#7A7A7A] md:text-[20px] text-[12px] font-roboto text-center'>Managing Director</h2>
                                </div>
                            </div>
                        </SwiperSlide>



                    </Swiper>
                </div>

            </div>

            <Footer />


        </>
    )
}

export default AboutPage