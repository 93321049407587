import React from 'react'
import banner from '../../../assest/contactImage/banner2.webp'
import Navbar from '../../homecomponent/Navbar';
import { BsDownload } from 'react-icons/bs';
import Footer from '../../homecomponent/Footer';
import { useState } from 'react';
import {
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
} from "tw-elements-react";
import AnnualReport from './AnnualReport';
import Subsidiaries from './Subsidiaries';
import FinancialResult from './financialResult/FinancialResult';
import Earning from './Earning';
import Research_Coverage from './Research_Coverage';
import Annual_Return from './Annual_Return';
import Investor_Call_Recording from './Investor_Call_Recording';

const Financial = () => {

    const [verticalActive, setVerticalActive] = useState("tab1");

    const handleVerticalClick = (value: string) => {
        if (value === verticalActive) {
            return;
        }
        setVerticalActive(value);
    };

    return (
        <>
            <div className='max-w-[1920px] md:h-[500px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[30px] mt-[100px] md:gap-[16px] gap-[1px]'>
                            <div className='pb-[30px] font-black md:text-[70px] text-[35px] text-white text-center uppercase'>Financial
                            </div>

                            <h1 className=' md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto text-center'>APL Apollo Tubes Limited is India’s largest Structural Steel Tubing Company with
                                an extremely strong local presenc
                            </h1>

                            <div className='w-[176px] h-[2px] bg-white mt-[70px]'></div>

                        </div>

                    </div>
                </div>

            </div>


            <div className='flex md:flex-row flex-col my-[50px] md:pl-[50px] pl-[10px] md:pr-[20px] pr-[10px] gap-2 '>

                <TETabs vertical className='md:w-[332px] w-full h-fit border border-[#BCBCBC]'>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab1")}
                        active={verticalActive === "tab1"}
                        className='text-[18px]  font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>Annual Report
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab2")}
                        active={verticalActive === "tab2"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Subsidiaries
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab3")}
                        active={verticalActive === "tab3"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Financial Results
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab4")}
                        active={verticalActive === "tab4"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Earning/ Investor Call Transcript
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab5")}
                        active={verticalActive === "tab5"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Postal Ballot Results
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab6")}
                        active={verticalActive === "tab6"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Research Coverage
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab7")}
                        active={verticalActive === "tab7"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Earning/ Investor Call Recording
                    </TETabsItem>

                    <TETabsItem
                        onClick={() => handleVerticalClick("tab8")}
                        active={verticalActive === "tab8"}
                        className='text-[18px] font-roboto font-semibold hover:bg-[#F47E32] duration-300 hover:text-white text-[#727272] h-[47px] items-center flex'>
                        Annual Returns
                    </TETabsItem>

                </TETabs>



                <TETabsContent>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab1"}><AnnualReport /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab2"}><Subsidiaries /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab3"}><FinancialResult /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab4"}><Earning /></TETabsPane>
                    {/* <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab5"}><Postal_Ballot_Results /></TETabsPane> */}
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab6"}><Research_Coverage /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab7"}><Investor_Call_Recording /></TETabsPane>
                    <TETabsPane className='md:pl-[30px] pl-0' show={verticalActive === "tab8"}><Annual_Return /></TETabsPane>

                </TETabsContent>


            </div>

            <Footer />


        </>

    )
}

export default Financial