import React from 'react'
import banner from '../assest/contactImage/banner2.webp';
import Navbar from './homecomponent/Navbar';
import Footer from './homecomponent/Footer';
import pdflogo from '../assest/pdfLogo.jpg';
import creditpdf from '../assest/Website/ICRAAPLApolloBuildingProductsPrivateLimited.pdf';


const CraditRating = () => {
    return (
        <>


            <div className='max-w-[1920px] md:h-[620px] h-[300px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[110px] mt-[50px] gap-[16px]'>
                            <div className='pb-[30px] font-black  md:text-[70px] text-white text-center uppercase'>GENERAL INFORMATION
                            </div>

                            {/* <h1 className='md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto  text-center'>Coming soon </h1> */}
                            <div className='w-[176px] h-[2px] bg-white '></div>

                        </div>

                    </div>
                </div>

            </div>



            <div className='flex flex-col mt-[50px] items-center md:gap-[33px] gap-[10px]'>

                <div className='border-b-2 border-gray-300 md:w-[800px] w-full md:h-[35px] h-[56px]  '>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] md:pl-0 pl-4 font-bold font-roboto uppercase'>Financial Year 2023</h1>
                </div>

                <div className=' md:w-[800px] w-full md:h-[30px] h-[56px]   flex  items-center  gap-[100px] p-1'>
                    <div className='flex items-center gap-[70px]'>
                        <img src={pdflogo} className='' />
                        <h1 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto'><a href={creditpdf}>ICRA APL Apollo Building Products Private Limited.pdf</a></h1>
                    </div>


                </div>
            </div>



            <Footer />
        </>
    )
}

export default CraditRating