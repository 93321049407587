import React from 'react'
import ConatctPage1 from './contactComponent/ConatctPage1'



const MainContactFile = () => {
    return (
        <>
            <ConatctPage1 />
            


        </>
    )
}

export default MainContactFile