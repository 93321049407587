import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import banner from '../../assest/contactImage/banner4.webp';
import Navbar from '../homecomponent/Navbar';
import ruftuff from '../../assest/contactImage/rufftuff.webp';
import colorImage from '../../assest/contactImage/colorImage.webp';
import ingenuity from '../../assest/contactImage/ingunityImage.webp';
import swa2 from '../../assest/productImage/swa2.webp';
import rootuf from '../../assest/productImage/rooftuff.webp';
import pa4 from '../../assest/contactImage/pa4.webp';
import pa5 from '../../assest/contactImage/pa5.webp';
import pa6 from '../../assest/contactImage/pa6.webp';
import coat from '../../assest/productImage/coat.webp'
import micron from '../../assest/productImage/micron.webp';
import smp from '../../assest/productImage/smp.webp';
import metalic from '../../assest/productImage/metalic.webp';
import coil from '../../assest/productImage/coilwidth.webp';
import bis from '../../assest/productImage/bis.webp';
import quv from '../../assest/productImage/quv.webp';
import humidity from '../../assest/productImage/humidity.webp';
import mek from '../../assest/productImage/mek.webp';
import salt from '../../assest/productImage/salt.webp';
import impact from '../../assest/productImage/impact.webp';
import shed1 from '../../assest/productImage/ind.sheds.webp';
import shed2 from '../../assest/productImage/comm,buildings.webp';
import shed3 from '../../assest/productImage/res.roofs.webp';
import shed4 from '../../assest/productImage/war.sheds.webp';
import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import cloude from '../../assest/cloudy.png';
import weather from '../../assest/weather.png';
import drop from '../../assest/drop-arrow.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Footer from '../homecomponent/Footer';
import { TbAlignBoxCenterStretch } from "react-icons/tb";



const Product3 = () => {
    return (
        <>

            <div className='max-w-[1620px] md:h-[720px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            className=' bg-[#00ACB6]/70 md:ml-[250px] md:mr-[250px]'>
                            <div className='flex flex-col justify-center items-center mt-[130px] gap-[10px] p-[30px] '>
                                <div className='md:pb-[30px] font-black md:text-[60px] text-[30px] text-white text-center uppercase'>Apollo Exclusive
                                </div>

                                <h1 className='text-[24px] text-[#FFFFFF] font-semibold font-lato text-justify'>Apollo Roof Tuff</h1>
                                <div className='w-[176px] h-[2px] bg-white mt-[70px]'></div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/*  */}





            {/* for mobile */}
            <div className='md:hidden block   flex-col '>
                <div className='mt-[10px] pl-[10px] pr-[10px]'>
                    <h4 className='text-[25px] font-roboto font-extrabold text-center'>APL Apollo Roof Tuff</h4>
                </div>

            </div>



            <section className='flex md:flex-row flex-col md:gap-[110px] gap-[11px] md:mt-[72px] mt-[40px] md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px]'>

                <div className='md:w-[45%] w-full md:h-[570px] h-[300px] shadow-2xl shadow-[#00000026] '>
                    <img src={rootuf} alt="rootuff" className='w-full h-full' />

                </div>


                <div className='md:w-[55%] w-full md:mt-0 mt-4 md:pr-[50px]'>

                    <div data-aos="fade-down" data-aos-duration='1000' className='md:block hidden'>
                        <h4 className='text-[55px] font-roboto font-extrabold '>APL Apollo Roof Tuff</h4>
                        <i className='text-[25px] text-[#4E4E4E] font-roboto font-semibold'>Roofing India with pride</i>
                    </div>

                    <div data-aos="fade-down" data-aos-duration='1000'>

                        <p className='text-[#4E4E4E] md:text-[17px] text-[17px] font-roboto overflow-wrap-break-word tracking-wide' >
                            APL Apollo RoofTuff comes in a variety of attractive colors and
                            is suitable for residential, industrial, warehouse, and commercial
                            roofing application, where it improves both protection and aesthetics.
                        </p>

                        <p className='text-[#4E4E4E] md:text-[17px] text-[17px] font-roboto overflow-wrap-break-word  tracking-wide' >
                            These coils and sheets are available from 900mm in width to 1450 mm.
                            with thickness ranging from 0.3mm to 3.2mm. When it comes to
                            paint, we use SMP paint with a coating ranging from 18 microns to
                            30 microns. Additionally, the galvanization of the sheet is done with
                            alu-zinc coating ranging from an 18-micron coating to 150 microns.
                        </p>



                    </div>


                    <div className='flex flex-col md:gap-[30px] mt-2 gap-[25px]'>

                        <div>
                            <h4 className='uppercase md:text-[25px] text-[20px] text-[#4E4E4E] md:text-left text-center md:mt-0 mt-5 md:font-medium font-bold'>What makes apl apollo rooftuff so <span className='md:text-[25px] text-[20px] font-semibold text-[#F47E32]'>  durable? </span></h4>
                        </div>

                        <div className='flex md:gap-[120px] md:justify-normal justify-between'>
                            <div className='flex flex-col justify-between '>
                                <img src={coat} alt="rootuff" className='w-[60px] md:h-[50px] h-fit' />
                                <h4 className='text-[#4E4E4E] md:text-[14px] text-[12px] font-roboto'>4 Coat Paint System</h4>
                            </div>

                            <div className='flex flex-col justify-between '>
                                <img src={micron} alt="rootuff" className='w-[60px] h-[50px]' />
                                <h4 className='text-[#4E4E4E] md:text-[14px] text-[12px]  font-roboto'>30 Micron Paint Coating</h4>
                            </div>

                            <div className='flex flex-col justify-between '>
                                <img src={smp} alt="rootuff" className='w-[60px] h-[50px]' />
                                <h4 className='text-[#4E4E4E] md:text-[14px] text-[12px] font-roboto'>SMP Paint</h4>
                            </div>

                        </div>

                        <div data-aos="fade-top" data-aos-duration='1000' className='flex md:gap-[80px] md:justify-normal justify-between'>

                            <div className='flex flex-col justify-between '>
                                <img src={metalic} alt="rootuff" className='w-[60px] h-[50px]' />
                                <h4 className='text-[#4E4E4E] md:text-[14px] text-[12px] font-roboto'>Metallic Coating AZ 70/AZ 150</h4>
                            </div>

                            <div className='flex flex-col justify-between '>
                                <img src={coil} alt="rootuff" className='w-[60px] h-[50px]' />
                                <h4 className='text-[#4E4E4E] md:text-[14px] text-[12px] font-roboto'>CoilWidth Up to 1440 mm</h4>
                            </div>

                            <div className='flex flex-col justify-between '>
                                <img src={bis} alt="rootuff" className='w-[60px] h-[50px]' />
                                <h4 className='text-[#4E4E4E] md:text-[14px] text-[12px] font-roboto'>BIS Certified</h4>
                            </div>

                        </div>

                    </div>
                </div>
            </section >




            <section className='md:block hidden  mt-[100px] ml-[45px] mr-[45px]'>

                <div>
                    <h4 className='uppercase md:text-[40px] text-[20px]   text-center font-bold'> quality tests</h4>
                    <p className='uppercase md:text-[25px] text-[20px] text-[#4E4E4E] font-medium text-center'>apl apollo rooftuff passes through stringent</p>
                </div>

                <div className='flex  mt-[50px]'>

                    <div className='flex flex-col items-center justify-center gap-1 w-1/4'>
                        <h4 className='md:text-[17px] text-[17px] font-medium text-[#4E4E4E] flex flex-col items-center'><span className='md:text-[16px] text-[13px] font-roboto font-semibold text-[#4E4E4E]'>QUV</span> (weather test)</h4>
                        <img src={quv} alt="rootuff" className='w-[80px] h-[80px]' />
                        <h4 className='text-[17px] text-[#F47E32] font-semibold md:mt-[10px] mt-[24px]'>1000 HOURS</h4>
                    </div>

                    <div className='flex flex-col items-center justify-center  gap-1 w-1/4'>
                        <h4 className='md:text-[17px] text-[17px]font-roboto font-semibold text-[#4E4E4E]'>Humidity</h4>
                        <img src={humidity} alt="rootuff" className='w-[80px] h-[80px] mt-[20px]' />
                        <h4 className='text-[17px] text-[#F47E32] font-semibold md:mt-[15px] mt-[23px]'>1000 HOURS</h4>
                    </div>


                    <div className='flex flex-col items-center justify-center  gap-1 w-1/4'>
                        <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>Salt spray test</h4>
                        <img src={salt} alt="rootuff" className='w-[80px] h-[80px]' />
                        <h4 className='text-[17px] text-[#F47E32]  font-semibold mt-[10px]'>1000 HOURS</h4>
                    </div>

                    <div className='flex flex-col items-center justify-center  gap-1 w-1/4'>
                        <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>Impact test</h4>
                        <img src={impact} alt="rootuff" className='w-[80px] h-[80px]' />
                        <h4 className='text-[17px] text-[#F47E32]  font-semibold mt-[10px]'>10 JOULE(t&gt;0.5MM)</h4>
                    </div>

                    <div className='flex flex-col items-center justify-center  gap-1 w-1/4'>
                        <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>Stretch test</h4>
                        <TbAlignBoxCenterStretch className='w-[80px] h-[80px] text-[#F47E32]' />
                        <h4 className='text-[17px] uppercase text-[#F47E32]  font-semibold mt-[10px]'>hardness test</h4>
                    </div>


                    <div className='flex flex-col items-center  justify-center gap-1 w-1/4'>
                        <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>MEK Solvent <br /> <span className='md:text-[14px] text-[12px] font-medium text-[#4E4E4E]'> (resistance test)</span></h4>
                        <img src={mek} alt="rootuff" className='w-[80px] h-[80px]' />
                        <h4 className='text-[17px] text-[#F47E32] text-center  font-semibold md:mt-[12px] mt-[10px]'>TOP/BOTTOM COAT MIN 100DR/50DR</h4>
                    </div>


                </div>


            </section>



            {/* mobile */}




            <section className='md:hidden block  mt-[50px] md:ml-[45px] ml-[10px] md:mr-[45px] mr-[10px]'>

                <div className='mb-6'>
                    <h4 className='uppercase  text-[20px]   text-center font-bold'> quality tests</h4>
                    <p className='uppercase text-[16px] text-[#4E4E4E] font-medium text-center'>apl apollo rooftuff passes through stringent</p>
                </div>

                <Swiper
                    slidesPerView={1}
                    // pagination={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 1000
                    }}
                    modules={[Pagination, Autoplay]}
                >

                    <div className=' flex justify-center mt-[90px]'>

                        <SwiperSlide>
                            <div className='flex flex-col items-center justify-center gap-1 '>
                                <h4 className='md:text-[17px] text-[17px] font-medium text-[#4E4E4E] flex flex-col items-center'><span className='md:text-[16px] text-[13px] font-roboto font-semibold text-[#4E4E4E]'>QUV</span> (weather test)</h4>
                                <img src={quv} alt="rootuff" className='w-[80px] h-[80px]' />
                                <h4 className='text-[17px] text-[#F47E32] font-semibold md:mt-[10px] mt-[24px]'>1000 HOURS</h4>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center justify-center  gap-1 '>
                                <h4 className='md:text-[17px] text-[17px]font-roboto font-semibold text-[#4E4E4E]'>Humidity</h4>
                                <img src={humidity} alt="rootuff" className='w-[80px] h-[80px] mt-[20px]' />
                                <h4 className='text-[17px] text-[#F47E32] font-semibold md:mt-[15px] mt-[23px]'>1000 HOURS</h4>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center justify-center  gap-1 '>
                                <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>Salt spray test</h4>
                                <img src={salt} alt="rootuff" className='w-[80px] h-[80px]' />
                                <h4 className='text-[17px] text-[#F47E32]  font-semibold mt-[10px]'>1000 HOURS</h4>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center justify-center  gap-1 '>
                                <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>Impact test</h4>
                                <img src={impact} alt="rootuff" className='w-[80px] h-[80px]' />
                                <h4 className='text-[17px] text-[#F47E32]  font-semibold mt-[10px]'>10 JOULE(t&gt;0.5MM)</h4>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center justify-center  gap-1 '>
                                <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>Stretch test</h4>
                                <TbAlignBoxCenterStretch className='w-[80px] h-[80px] text-[#F47E32]' />
                                <h4 className='text-[17px] uppercase text-[#F47E32]  font-semibold mt-[10px]'>hardness test</h4>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center  justify-center gap-1 '>
                                <h4 className='md:text-[17px] text-[17px] font-roboto font-semibold text-[#4E4E4E]'>MEK Solvent <br /> <span className='md:text-[14px] text-[12px] font-medium text-[#4E4E4E]'> (resistance test)</span></h4>
                                <img src={mek} alt="rootuff" className='w-[80px] h-[80px]' />
                                <h4 className='text-[17px] text-[#F47E32] text-center  font-semibold md:mt-[12px] mt-[10px]'>TOP/BOTTOM COAT MIN 100DR/50DR</h4>
                            </div>
                        </SwiperSlide>


                    </div>

                </Swiper>


            </section>




            {/* key benefits  */}


            {/* <section className='mt-[50px] md:pl-[50px]'>
                <h3 className=' md:text-[40px] text-[15px] font-bold font-roboto pl-[33px] text-start mt-[10px] ' >KEY  <span className='text-[#F47E32]'>BENEFITS</span></h3>

                <div className='flex mt-[30px] gap-[40px]  justify-center'>

                    <div className='flex gap-3 items-center w-1/3'>
                        <img src={cloude} alt="weather" />
                        <p className='uppercase text-[18px]  text-[#4E4E4E] font-medium'>Suitable for High
                            Temperature Areas
                            apl apollo rooftuff passes through stringent quality tests</p>
                    </div>

                    <div className='flex gap-3 items-center w-1/3'>
                        <img src={weather} alt="weather" />
                        <p className='uppercase text-[18px]  text-[#4E4E4E] font-medium'>
                            Suitable for All
                            Weather Conditions
                        </p>
                    </div>

                    <div className='flex gap-3 items-center w-1/3'>
                        <img src={drop} alt="weather" className='bg-blue-500' />
                        <p className='uppercase text-[18px] text-[#4E4E4E] font-medium'>
                            Corrosion
                            Resistant
                        </p>
                    </div>

                </div>




            </section> */}




            <section className='flex md:gap-[100px] md:justify-normal justify-center md:pl-[40px] pl-[10px] md:pr-[40px] pr-[10px] md:mt-[60px] mt-[20px] '>

                <div className='flex flex-col md:gap-[40px] gap-[17px]'>
                    <h4 className=' md:text-[40px] text-[15px] font-bold font-roboto md:pl-[33px] text-start mt-[10px] ' >EXTENSIVE RANGE OF <span className='text-[#F47E32]'>COLORS</span></h4>

                    <div className=' mt-[10px] flex justify-center items-center'>
                        <img src={colorImage} alt="rootuff" className='md:w-[770px] w-full  md:h-[360px] h-full' />
                    </div>

                </div>

                <div className='flex flex-col gap-[50px]'>
                    <h3 className=' md:text-[40px] text-[15px] font-bold font-roboto pl-[33px] text-start mt-[10px] ' >MARK OF <span className='text-[#F47E32]'>INGENUITY</span></h3>

                    <div className='flex items-baseline'>
                        <img src={ingenuity} alt="rootuff" className='md:w-[770px] w-full md:h-[360px] h-full' />
                    </div>
                </div>
            </section>









            {/* Product Application carousoles */}

            {/* Owl Crousol */}
            <div className='md:block hidden'>
                <div className='mt-[80px]'>
                    <h4 className='text-[#000000] text-[55px] font-roboto text-center font-extrabold'>
                        PRODUCT <span className='text-[#F47E32]'> APPLICATIONS</span>
                    </h4>
                </div>
                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={40}
                    slidesPerView={4}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    // pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className='w-[1400px] h-[300px]  mt-[76px]'

                >

                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={shed1} alt="rootuff" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={shed2} alt="rootuff" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[300px]'>
                            <img src={shed3} alt="rootuff" className=' w-full h-[300px] rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={shed4} alt="rootuff" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={shed2} alt="rootuff" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={shed1} alt="rootuff" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={shed4} alt="rootuff" className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* for  mobile responsive */}

            <div className='md:hidden block pl-[10px] pr-[10px]'>
                <div className='mt-[47px]'>
                    <h4 className='font-roboto md:text-[50px] text-[30px] font-extrabold'>
                        PRODUCT APPLICATIONS
                    </h4>
                </div>


                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={10}
                    slidesPerView={3}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        type: 'fraction',
                    }}

                    // scrollbar={{ draggable: true }}
                    className='max-w-[1400px] h-fit  mt-[32px]'

                >

                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt="rootuff" className='w-full h-full rounded' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* Structure careousel */}
            <section className='flex md:flex-row flex-col justify-between md:mt-[100px] my-[50px]'>

                <div className='flex flex-col md:pl-[50px] pl-[10px]  gap-[10px]'>

                    <h4 className='font-roboto md:text-[50px] text-[30px] font-extrabold'>STRUCTURAL IN OUR WORLD</h4>

                    <div className=''>
                        <button
                            className='text-[#F47E32]  flex justify-center font-roboto text-[15px] hover:bg-gray-900 hover:text-white duration-300 
 font-semibold items-center gap-[10px] w-[219px] h-[56px] border-2 border-[#F47E32] hover:border-white'>
                            <span>DOWNLOAD BROCHURE </span><BsBoxArrowInUpRight size={20} /> </button>
                    </div>
                </div>



                <div className='md:mt-[2px] mt-[30px] md:pl-[0px] pl-[10px]  md:pr-0 pr-[10px]'>
                    <Swiper
                        // onSwiper={setSwiperRef}
                        slidesPerView={2}
                        centeredSlides={true}
                        spaceBetween={30}
                        scrollbar={{ draggable: true }}
                        pagination={{
                            type: 'fraction',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination, Autoplay, Navigation, Scrollbar]}
                        className='md:w-[900px] w-full md:h-[350px] h-fit'
                    >
                        <div >

                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt="rootuff" />
                            </SwiperSlide>
                        </div>
                    </Swiper>

                </div>

            </section>

            <Footer />

        </>
    )
}

export default Product3;