import React from 'react'
import banner from '../../assest/contactImage/banner4.webp';
import Navbar from '../homecomponent/Navbar';
import Footer from '../homecomponent/Footer';
import { BsDownload } from 'react-icons/bs';


const Investor = () => {
    return (
        <>

            <div className='max-w-[1920px] md:h-[620px] h-[400px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[170px] mt-[50px] gap-[16px]'>
                            <div className='pb-[30px] font-black mdtext-[80px] text-[50px] text-white text-center uppercase'>Investor Relationship
                            </div>

                            {/* <h1 className='md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto  text-center'>Coming soon </h1>
                            <div className='w-[176px] h-[2px] bg-white mt-[70px]'></div> */}

                        </div>

                    </div>
                </div>

            </div>


            <div className='flex flex-col items-center mt-[50px] md:gap-[15px] '>

                <div className='  md:w-[800px] w-full  flex   items-center  p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>a.  Financial Information</h1>
                    {/* <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Download Annual Report</h1> */}
                </div>

                <div className='border-b-2 pb-4   md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]   flex   items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>i.  Annual Report</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4 md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>ii. Annual Return</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4 md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px]  font-medium font-roboto'>iii. Financial Results</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='/financialResult' target='blank'> <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4 md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF] flex     items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px]  font-medium font-roboto'>iv. Stock Exchange Intimations</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='/stockexcahnge' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

            </div>



            <div className='flex flex-col items-center mt-[20px] md:gap-[15px] '>

                <div className='  md:w-[800px] w-full  flex   items-center  p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>b.  Announcement</h1>
                    {/* <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Download Annual Report</h1> */}
                </div>

                <div className='border-b-2  pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]   flex   items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>i.  Outcome of Board Meeting</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='/outcome' target='blank' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>ii. Notice of Board Meeting</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='/bordMeeting' target='blank'> <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>iii. Notice of AGM/EGM</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>
            </div>

            <div className='flex flex-col items-center mt-[20px] md:gap-[15px] '>

                <div className='  md:w-[800px] w-full  flex   items-center  p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>c.  General Information</h1>
                    {/* <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Download Annual Report</h1> */}
                </div>

                <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]   flex   items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>i.  Board Composition</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4 md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>ii. Details of Debenture Trustee</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='/deetai_dt' target='blank' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>iii. Credit Ratings</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='/credit_Rating' target='blank' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>iv. Grievance management</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                {/* <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>v. Related Party Transactions</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div> */}

                {/* <div className='border-b-2  pb-4 md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>vi. Corporate Governance</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div> */}

            </div>




            <div className='flex flex-col items-center mt-[20px] md:gap-[15px] '>

                <div className='  md:w-[800px] w-full  flex   items-center  p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>d.  Downloads</h1>
                    {/* <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Download Annual Report</h1> */}
                </div>

                <div className='border-b-2 pb-4  md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]   flex   items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>i.  Policies</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div>

                {/* <div className='border-b-2 pb-4 md:w-[800px] w-full md:h-[30px] h-[56px] border-[#EFEFEF]  flex    items-center  justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-medium font-roboto'>ii. Forms</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <a href='#' > <h1 className=' font-semibol font-roboto'>Click here</h1></a>
                    </div>
                </div> */}
            </div>


            <Footer />




        </>
    )
}

export default Investor