import React from 'react'
import banner from '../../../assest/contactImage/banner4.webp';
import Navbar from '../../homecomponent/Navbar';
import businessImage from '../../../assest/contactImage/bbusinessmanImage1.webp';
import businessImage2 from '../../../assest/contactImage/businessmanImage2.webp';
import Footer from '../../homecomponent/Footer';


const Corporate = () => {
    return (
        <>


            <div className='relative flex justify-around md:mb-[200px] '>
                <div className='flex md:flex-row flex-col  gap-[50px]'>
                    <div className='md:w-[500px] w-full md:h-[630px]'>
                        <img src={businessImage} className='w-full h-full' />
                    </div>


                    <div className=' flex items-center '>

                        <p className='md:w-[500px] h-[615px] md:text-[20px] text-[16px] text-[#535353] font-roboto'>Corporate Governance at APL Apollo Tubes Limited has been a continuous journey and the business goals of the Company are aimed at the well being and welfare of all the constituents of the system. The company had laid a strong foundation for making Corporate Governance way of life by constituting a Broad with a balanced mix of experts of eminence and integrity, forming a core group of top level executives, inducting competent professionals accross the organisation and putting in place appropriate systems, process and technology, it is believed that the imperative for good corporate Governance lies not merely in drafting a code of Corporate Governance but in practicing it.</p>
                    </div>
                    
                    <img src={businessImage2} className='absolute w-[524px] h-[300px] bottom-[-250px] right-[320px] md:block hidden' />

                </div>

            </div>


        </>
    )
}

export default Corporate