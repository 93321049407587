import React from 'react'
import Logo from '../../assest/homeImage/aplLogo.webp';
import { RiTwitterXLine } from 'react-icons/ri';
import { AiOutlineInstagram } from 'react-icons/ai';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiFillYoutube } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import AOS from 'aos';


const Footer = () => {


    AOS.init();



    return (
        <>

            <div className='max-w-full md:h-[426px] h-[350px] mt-[59px] bg-[#101010]'>

                <div className='md:pl-[100px] pl-[10px] md:pr-[100px] pr-[10px] md:pt-[50px] pt-[20px]'>

                    <Link to={'/'}>  <img src={Logo} className='md:w-[188px] w-[90px] md:h-[75px] h-[35px] md:pl-[0px] ml-[30px]' /></Link>

                    <div className='md:block hidden border-b border-[#888888] mt-[54px]'>
                    </div>

                    <div className='flex md:flex-row flex-col justify-between items-center'>

                        <div className='flex md:flex-row flex-col md:gap-[74px] gap-[20px] md:mt-[59px] mt-[30px]'>
                            <div className='flex justify-between md:gap-[74px] gap-[30px]'>
                                <a className=" text-[#B4B4B4] md:text-[18px] text-[15px] font-roboto hover hover:text-[#FE6910] duration-300"><Link to={"/aboutus"}>  ABOUT US</Link> </a>
                                <a className=" text-[#B4B4B4] md:text-[18px] text-[15px] font-roboto hover hover:text-[#FE6910] duration-300"><Link to={"/navrang"}>  PRODUCTS</Link> </a>
                                <a className=" text-[#B4B4B4] md:text-[18px] text-[15px] font-roboto hover hover:text-[#FE6910] duration-300"><Link to={"/investor"}>  INVESTORS</Link> </a>
                            </div>

                            <div className='flex md:gap-[74px] gap-[30px]'>
                                {/* <a className=' text-[#B4B4B4] hover:text-[#FE6910] md:text-[18px] text-[15px] font-roboto duration-300' ><Link to={''}>BLOG </Link></a> */}
                                <a className=" text-[#B4B4B4] md:text-[18px] text-[15px] font-roboto hover hover:text-[#FE6910] duration-300"><Link to={"/contactus"}>CONTACT</Link> </a>
                            </div>
                        </div>

                        <div className='text-[#A0A0A0] flex md:gap-[22px] gap-[15px] pt-[40px]'>
                            <a target='blank'
                                data-aos="fade-down"
                                data-aos-duration="1000"
                                href='https://twitter.com/aplapollo_tubes'> <RiTwitterXLine size={40} className='border border-white rounded-full p-3 hover:bg-[#fe6910] shadow shadow-[#fe6910] duration-300 hover:text-white' /></a>

                            <a target='blank'
                                data-aos="fade-down"
                                data-aos-duration="1200"
                                href='https://www.instagram.com/aplapollo.official/'>  <AiOutlineInstagram size={40} className='border border-white rounded-full p-3 hover:bg-[#FE6910] shadow shadow-[#fe6910] duration-300 hover:text-white' /></a>

                            <a target='blank'
                                data-aos="fade-down"
                                data-aos-duration="1400"
                                href='https://www.facebook.com/aplapollo.official'> <BiLogoFacebook size={40} className='border border-white rounded-full p-3 hover:bg-[#FE6910] shadow shadow-[#fe6910] duration-300 hover:text-white' /></a>

                            <a target='blank'
                                data-aos="fade-down"
                                data-aos-duration="1600"
                                href='https://www.youtube.com/c/APLAPOLLO-official?themeRefresh=1'> <AiFillYoutube size={40} className='border border-white rounded-full p-3 hover:bg-[#FE6910] shadow shadow-[#fe6910] duration-300 hover:text-white' /></a>

                            <a target='blank'
                                data-aos="fade-down"
                                data-aos-duration="1800"
                                href='https://www.linkedin.com/company/aplapollo-official/'> <FaLinkedinIn size={40} className='border border-white rounded-full p-3 hover:bg-[#FE6910] shadow shadow-[#fe6910] duration-300 hover:text-white' /></a>
                        </div>

                    </div>

                    <div className='md:block hidden border-b border-[#888888] mt-[54px]'></div>


                    <div className='flex md:flex-row flex-col justify-between items-center'>

                        {/* <div className='flex md:gap-[84px] gap-[30px] md:mt-[59px] mt-[20px] group'>
                            <div className='flex gap-[20px]'>
                                <a target='blank' href="#" className=' text-[#B4B4B4] md:text-[14px] text-[10px] font-roboto hover:text-[#FE6910] duration-300' >Privacy Statement</a>
                                <a href="#" className=' text-[#B4B4B4] md:text-[14px] text-[10px] font-roboto hover:text-[#FE6910] duration-300' >Terms & Conditions</a>
                            </div>

                            <div className='flex gap-[20px]'>
                                <a href="#" className=' text-[#B4B4B4] md:text-[14px] text-[10px] font-roboto hover:text-[#FE6910] duration-300' >Cookie Policy/Settings</a>
                                <a href="#" className=' text-[#B4B4B4] md:text-[14px] text-[10px] font-roboto hover:text-[#FE6910] duration-300' >Accessibility Statement</a>
                                <a href="#" className=' text-[#B4B4B4] md:text-[14px] text-[10px] font-roboto hover:text-[#FE6910] duration-300' >Sitemap</a>
                            </div>

                        </div> */}

                        <div className='text-[#A0A0A0] flex gap-[32px] md:pt-[65px] pt-[30px]'>
                            <h1 className='text-[12px]'>© 2024 APL Apollo Building Products Pvt Ltd. All Rights Reserved.</h1>
                        </div>

                    </div>



                </div>

            </div>



        </>
    )
}

export default Footer