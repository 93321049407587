import React from 'react'
import { BsDownload } from 'react-icons/bs';

const Others = () => {
    return (
        <>

            <div className='flex flex-col md:gap-[33px] gap-[10px]'>

                <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] flex md:gap-[450px] md:pl-[50px] items-center md:justify-normal justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Financial Year</h1>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Download Annual Report</h1>

                </div>

                <div className='border  md:w-[1000px] w-full md:h-[30px] h-[56px] border-[#EFEFEF] shadow shadow-[#0000001A]  rounded-[5px] flex md:gap-[565px]  md:pl-[50px] items-center md:justify-normal justify-between p-1'>
                    <h1 className='text-[#3D3D3D] md:text-[14px] text-[14px] font-bold font-roboto'>2021-22</h1>
                    <div className='flex md:gap-4 gap-1 items-center text-[#00ACB6] md:text-[14px] text-[17px]'>
                        <BsDownload />
                        <h1 className=' font-semibol font-roboto'>Download</h1>
                    </div>

                </div>



            </div>

        </>

    )
}

export default Others