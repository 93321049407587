
import Footer from './homecomponent/Footer';
import Header from '../component/homecomponent/Header';
import { BsArrowRight } from 'react-icons/bs';
import Pipe from '../assest/homeImage/pipeImage.webp';
import productImage from '../assest/homeImage/latestProductImage.webp';
import img1 from '../assest/homeImage/image1.webp';
import img2 from '../assest/homeImage/image2.webp';
import img3 from '../assest/homeImage/image3.webp';
import im1 from '../assest/homeImage/CareerImage1.webp';
import im2 from '../assest/homeImage/CareerImage2.webp';
import im3 from '../assest/homeImage/CareerImage3.webp';
import cpa1 from '../assest/homeImage/cpa1.webp';
import cpa2 from '../assest/homeImage/cpa2.webp';
import cpaa3 from '../assest/homeImage/cpaa3.webp';
import cpa4 from '../assest/homeImage/cpa4.webp';
import cpa5 from '../assest/homeImage/cpa5.webp';
import cpa6 from '../assest/homeImage/cpa6.webp';
import { Link, } from 'react-router-dom'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import CountUp from 'react-countup';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';

import 'swiper/swiper-bundle.css';

import ScrollTrigger from 'react-scroll-trigger';

import AOS from 'aos';
import NavButtons from './NavButtons';



function Home({ images }) {

    AOS.init({ duration: 900 });


    const [countOn, setcountOn] = useState(0);
    const spread = <CountUp start={0} end={600} duration={3} delay={0} />
    const coverd = <CountUp start={0} end={200} duration={3} delay={0} />
    const capa = <CountUp suffix='.5' start={0} end={3} duration={2} delay={0} />
    const uti = <CountUp suffix='%' start={0} end={70} duration={3} delay={0} />


    return (
        <>
            <Header />

            {/* who we are */}
            <ScrollTrigger onEnter={() => setcountOn(true)} onExit={() => setcountOn(false)}>

                <div className='flex md:flex-row flex-col md:gap-[110px] gap-[50px]'>
                    <div data-aos="fade-up-left" className='md:w-1/3 w-full flex flex-col gap-[10px] md:pl-[70px] md:justify-normal justify-center md:mt-[140px] mt-[30px]'>
                        <h2 className='uppercase font-roboto  md:text-[55px] text-[35px] text-start md:pl-0 pl-2 font-bold	'>Who we are</h2>
                        <p className='md:text-[25px] md:pl-0 pl-2 font-roboto  pr-2'>APL Apollo Building Products Pvt Ltd. (ABPL) is a wholly owned subsidiary
                            of APL Apollo Tubes Ltd. Our plant is situated at Village Simga, Baloda Bazar district in Chhattisgarh (almost 50 Km from
                            Raipur Airport) manufacturing Rooftuff, Lume Tuff & Rakshak. </p>

                    </div>


                    <div className=' flex md:justify-normal justify-center'>
                        <div
                            data-aos="fade-down"
                            className='md:absolute md:w-[741px] w-[360px] md:h-[674px] h-[385px] bg-[#FE6910]/80  md:top-[745px]  flex md:justify-normal justify-center'>
                            <div className='md:mt-[63px] md:pl-[80px]'>

                                <div className='flex '>

                                    <div>

                                        <div className='md:w-[241px] w-[150px] md:h-[241px] h-[150px] bg-[#FBEADE] pl-[25px] flex flex-col justify-center btn transition-all  group relative'>
                                            <span className="w-0 h-0  bg-white/40 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                            <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>
                                            <p className='md:text-[38px] text-[22px] text-[#555555] font-extrabold	text-start font-roboto'>{countOn && capa} MTPA</p>
                                            <p className='md:text-[20px] text-[16px] text-[#555555] text-start pr-[10px] font-roboto'>Total Capacity</p>
                                        </div>

                                        <div className='md:w-[241px] w-[150px] md:h-[241px] h-[150px] bg-[#D38B36] pl-[25px] flex flex-col justify-center  btn transition-all  group relative'>
                                            <span className="w-0 h-0  bg-white/30 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                            <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>
                                            <p className='md:text-[38px] text-[22px] text-[#FFFF] font-extrabold	text-start font-roboto'>{countOn && spread} ACRES</p>
                                            <p className='md:text-[20px] text-[16px] text-[#FFFF] text-start pr-[10px] font-roboto'>Plant Spread </p>
                                        </div>

                                    </div>

                                    <div className='relative md:top-[241px] top-[150px]'>

                                        <div className='md:w-[241px] w-[150px] md:h-[241px] h-[150px] bg-[#84491F] pl-[25px] flex flex-col justify-center btn transition-all  group relative'>
                                            <span className="w-0 h-0  bg-white/30 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                            <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>
                                            <p className='md:text-[38px] text-[22px] text-[#FFFF] font-extrabold	text-start font-roboto'>{countOn && coverd} ACRES</p>
                                            <p className='md:text-[20px] text-[16px] text-[#FFFF] text-start pr-[10px] font-roboto'>Covered Area</p>
                                        </div>

                                        <div className='md:w-[241px] w-[150px] md:h-[241px] h-[150px] bg-[#0E1A2B] pl-[25px] flex flex-col justify-center btn transition-all  group relative '>

                                            <span className="w-0 h-0  bg-white/30 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                            <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>

                                            <p className='md:text-[38px] text-[22px] text-[#FFFF] font-extrabold	text-start font-roboto'>1 MN TON</p>
                                            <p className='md:text-[20px] text-[16px] text-[#FFFF] text-start pr-[10px] font-roboto'> Annual Capacity</p>
                                        </div>

                                    </div>

                                </div>


                            </div>

                        </div>

                    </div>


                </div>


                <div className='md:pl-[70px] pl-[10px] md:mt-[20px] mt-[70px]'>

                    <Link to={'/aboutus'}>
                        <button type='button'
                            className='w-[218px] h-[56px]  inline-flex   bg-[#FE6910]/100 hover:bg-gray-900 duration-300 justify-evenly items-center md:mt-[56px] mt-[20px]'>
                            <div className='gap-[30px]    inline-flex items-center justify-center   font-bold text-white transition-all duration-200    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FE6910]'>
                                <p className='text-[17px] font-roboto font-medium text-white '>About </p>
                                <BsArrowRight size={30} className='text-white ' />
                            </div>
                        </button>
                    </Link>

                </div>

                {/* Numering */}

                <div className='flex md:flex-row flex-col md:gap-[100px]  justify-center md:pl-0 pl-[10px] md:pr-0 pr-[10px] '>

                    <div
                        data-aos="fade-down"
                        className='md:py-[184px] py-[30px] flex md:justify-normal justify-between md:p-0 p-3  md:gap-[100px] items-center'>

                        <div>
                            <div>
                                <p className='md:text-[80px] text-[30px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'>{countOn && spread}</p>

                                <p className='pl-[15px] text-bottom text-[20px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'>Acres</p>
                            </div>
                            <p className='md:text-[22px] text-[17px] text-[#4D4D4D] font-roboto'>Plant Spread Across</p>
                        </div>

                        <div className='md:block hidden w-[1.5px] h-[100px]  bg-gradient-to-b from-[#F47E32] to-[#00ACB6]'></div>

                        <div className=''>
                            <div>
                                <p className='md:text-[80px] text-[30px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'> {countOn && coverd} </p>

                                <p className='pl-[15px] text-bottom text-[20px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'>Acres</p>
                            </div>
                            <p className='md:text-[22px] text-[17px] text-[#4D4D4D] font-roboto'>Covered Area</p>

                        </div>

                        <div className='md:block hidden w-[1.5px] h-[100px]  bg-gradient-to-b from-[#F47E32] to-[#00ACB6]'></div>
                    </div>


                    <div
                        data-aos="fade-up"
                        className='flex md:justify-normal justify-between md:gap-[100px] items-center md:p-0 p-3'>
                        <div>
                            <div>
                                <p className='md:text-[80px] text-[30px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'>{countOn && capa}</p>

                                <p className='pl-[15px] text-bottom text-[20px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'>MTPA</p>
                            </div>

                            <p className='md:text-[22px] text-[17px] text-[#4D4D4D] font-roboto'>Total Capacity</p>
                        </div>

                        <div className='md:block hidden w-[1.5px] h-[100px]  bg-gradient-to-b from-[#F47E32] to-[#00ACB6]'></div>

                        <div>
                            <div>
                                <p className='md:text-[80px] text-[30px] font-bold font-roboto bg-gradient-to-r from-[#FE6910] via-[#FF6E10] to-[#FF6E10] inline-block text-transparent bg-clip-text'>{countOn && uti}</p>

                            </div>

                            <p className='md:text-[22px] text-[17px] text-[#4D4D4D] font-roboto'>Utilization</p>

                        </div>
                    </div>
                </div>
            </ScrollTrigger>





            {/* Manufactering process */}

            <div className='relative md:max-w-[1920px] md:h-[1000px] h-fit md:pb-0 pb-[20px] bg-gradient-to-b from-[#005C61]/60 to-[#000000]'>

                <div className='absolute top-0  right-0 w-fit h-fit'>
                    <img src={Pipe} alt="apl products" className='md:w-[500px] w-[200px] md:h-[500px]  h-[200px] brightness-50' />
                </div>


                <div className='md:pt-[133px] pt-[150px] md:pl-[120px]  z-50'>
                    <p className='md:text-[55px] pl-[10px] text-[20px] font-bold font-roboto text-[#FFFFFF]'>APL Apollo Building Products <br /> Pvt Ltd. Facilities</p>
                </div>



                <Swiper
                    data-aos="zoom-in-up"
                    modules={[Navigation, Autoplay, Scrollbar, A11y, EffectCoverflow]}
                    slidesPerView={1}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className='md:w-[1410px] w-[355px] h-fit md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px] relative '
                >


                    <div className=' '>
                        {/* Arrow for carasol */}
                        <SwiperSlide className=''>

                            <div className='relative md:w-[1450px] w-[355px] md:h-[405px] h-fit md:p-0 p-3 bg-white md:mt-[200px] mt-[60px] '>

                                <div className='flex md:justify-normal  items-center gap-2'>

                                    <div className='md:absolute md:w-[405px] w-[290px] md:h-[459px] md:pl-[46px] bottom-0'>
                                        <img src={cpa1} alt="apl products" />
                                    </div>

                                    {/* Text Area */}

                                    <div className='md:pl-[500px] md:pt-[61px] flex flex-col md:gap-[43px] gap-[10px] '>

                                        <div className='flex flex-col md:gap-[31px] gap-[15px] text-center mt-[50px]'>
                                            <p className=' md:text-[36px] text-[15px] font-bold font-roboto'>World’s 1st thicker color-coated products</p>
                                            <p className='md:text-[25px] text-[10px] font-roboto font-medium'>Superior corrosion resistance, high load bearing with <br /> aesthetics thickness of 1.60mm to 3 mm</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>


                        <SwiperSlide id='' className=''>
                            <div className='relative md:w-[1450px] w-[355px] md:h-[405px] h-fit md:p-0 p-3 bg-white md:mt-[200px] mt-[60px] '>

                                <div className='flex md:justify-normal  items-center gap-2'>

                                    <div className='md:absolute md:w-[405px] w-[290px] md:h-[459px] md:pl-[46px] bottom-0'>
                                        <img src={cpa2} alt="apl products" />
                                    </div>

                                    {/* Text Area */}

                                    <div className='md:pl-[500px] md:pt-[61px] flex flex-col md:gap-[43px] gap-[10px] text-center'>

                                        <div className='flex flex-col md:gap-[31px] gap-[15px] mt-[50px]'>
                                            <p className=' md:text-[36px] text-[15px] font-bold font-roboto'>India’s 1st and World’s 2nd 1000 x 1000 mm</p>
                                            <p className='md:text-[25px] text-[10px] font-roboto font-medium'>Replacing RCC structures and columns in heavy construction.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>

                        <SwiperSlide className=''>
                            <div className='relative md:w-[1450px] w-[355px] md:h-[405px] h-fit md:p-0 p-3 bg-white md:mt-[200px] mt-[60px] '>

                                <div className='flex md:justify-normal  items-center gap-2'>

                                    <div className='md:absolute md:w-[405px] w-[290px] md:h-[459px] md:pl-[46px] bottom-0'>
                                        <img src={cpaa3} alt="apl products" />
                                    </div>

                                    {/* Text Area */}

                                    <div className='md:pl-[500px] md:pt-[61px] flex flex-col md:gap-[43px] gap-[10px] text-center'>

                                        <div className='flex flex-col md:gap-[31px] gap-[15px] mt-[50px]'>
                                            <p className=' md:text-[36px] text-[15px] font-bold font-roboto'>World’s 1st color-coated structural steel tubes</p>
                                            <p className='md:text-[25px] text-[10px] font-roboto font-medium'>Superior corrosion resistance with aesthetics.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>


                        {/* <SwiperSlide className=''>
                            <div className='relative md:w-[1450px] w-[355px] md:h-[405px] h-fit md:p-0 p-3 bg-white md:mt-[200px] mt-[60px] '>


                                <div className='flex md:justify-normal  items-center gap-2'>

                                    <div className='md:absolute md:w-[405px] w-[290px] md:h-[459px] md:pl-[46px] bottom-0'>
                                        <img src={cpa4} alt="apl products" />
                                    </div>


                                    <div className='md:pl-[550px] md:pt-[61px] flex flex-col md:gap-[43px] gap-[10px] text-center'>

                                        <div className='flex flex-col md:gap-[31px] gap-[15px] mt-[50px]'>
                                            <p className=' md:text-[36px] text-[15px] font-bold font-roboto'>India’s 1st and World’s 2nd 1000 x 1000 mm</p>
                                            <p className='md:text-[25px] text-[10px] font-roboto font-medium'>Replacing RCC structures and columns in heavy construction.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide> */}

                        <SwiperSlide className=''>
                            <div className='relative md:w-[1450px] w-[355px] md:h-[405px] h-fit md:p-0 p-3 bg-white md:mt-[200px] mt-[60px] '>
                                <div className='flex md:justify-normal  items-center gap-2'>

                                    <div className='md:absolute md:w-[405px] w-[290px] md:h-[459px] md:pl-[46px] bottom-0'>
                                        <img src={cpa5} alt="apl products" />
                                    </div>

                                    {/* Text Area */}

                                    <div className='md:pl-[500px] md:pt-[61px] flex flex-col md:gap-[43px] gap-[10px] text-center'>

                                        <div className='flex flex-col md:gap-[31px] gap-[15px] mt-[50px]'>
                                            <p className=' md:text-[36px] text-[15px] font-bold font-roboto'>India’s 1st thicker non-ox narrow galvanized up to 3.2mm</p>
                                            <p className='md:text-[25px] text-[10px] font-roboto font-medium'>High tensile strength, light structural application, bendability, and superior <br /> rust-proof properties</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>

                        <SwiperSlide className=''>
                            <div className='relative md:w-[1450px] w-[355px] md:h-[405px] h-fit md:p-0 p-3 bg-white md:mt-[200px] mt-[60px] '>

                                <div className='flex md:justify-normal  items-center gap-2'>

                                    <div

                                        className='md:absolute md:w-[405px] w-[290px] md:h-[459px] md:pl-[46px] bottom-0'>
                                        <img src={cpa6} alt="apl products" />
                                    </div>

                                    {/* Text Area */}

                                    <div className='md:pl-[600px] md:pt-[61px] flex flex-col md:gap-[43px] gap-[10px] text-center'>

                                        <div className='flex flex-col md:gap-[31px] gap-[15px] mt-[50px]'>
                                            <p className=' md:text-[36px] text-[15px] font-bold font-roboto'>India’s 1st Aluzinc tubes</p>
                                            <p className='md:text-[25px] text-[10px] font-roboto font-medium'>Superior rust-proof properties and a better life</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>

                    </div>


                    <div className='flex justify-center mt-[10px]'>
                        <NavButtons />
                    </div>
                </Swiper>

            </div>


            {/* our most selling Product Section  */}
            <div className='md:block hidden'>
                <div className='relative max-w-full h-fit pb-[50px] mx-auto bg-gradient-to-b from-[#005C61]/60 to-[#000000] md:mt-[50px] mt-[20px]'>

                    <div className='absolute top-0  right-0 w-fit h-fit '>
                        <img src={productImage} alt="apl products" className='max-w-[400px] md:h-[400px] h-[200px] mx-auto ' />
                    </div>

                    <div className='text-center md:pt-[68px] pt-[30px]'>
                        <p className='md:text-[55px] text-[23px] font-bold text-white font-roboto uppercase' >Our Most Selling Products</p>
                    </div>


                    <div className='flex justify-center md:pt-[115px] pt-[40px] md:pl-0 pl-2'>

                        <div className='  flex flex-col md:gap-[16px] gap-[10px] md:w-[400px] w-[150px] md:h-[890px] h-[200px]'>

                            <div data-aos="flip-left" className='btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group'>
                                <span className="w-0 h-0 rounded bg-white/30 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>
                                <img src={img1} alt='Product_Image' className='w-full h-full' />
                            </div>

                            <div data-aos="flip-left" className='text-start flex flex-col md:gap-[10px] gap-[3px]'>
                                <p className='md:text-[20px] text-[15px] font-roboto text-white'>APL Apollo </p>
                                <p className='md:text-[40px] text-[10px] font-medium font-roboto text-white'>Rooftuff</p>
                                <p className='md:text-[17px] text-[12px] md:pr-[30px] pr-[8px] text-[#D2D2D2] font-roboto'>
                                    APL Apollo Roof tuff is a range of color-coated Alu-zinc roofing sheets for high corrosion resistance with excellent aesthetics look, and longer life.
                                </p>
                            </div>

                        </div>

                        <div className=' flex flex-col md:gap-[16px] gap-[10px] md:w-[400px] w-[150px] md:h-[890px] h-[200px] md:pt-[82px] pt-[50px]'>

                            <div data-aos="flip-left" className='btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group'>
                                <span className="w-0 h-0 rounded bg-white/30 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>
                                <img src={img2} alt='Product_Image' className='w-full h-full' />
                            </div>

                            <div data-aos="flip-left" className='text-start flex flex-col md:gap-[10px] gap-[3px]'>
                                <p className='md:text-[20px]  font-roboto text-white'>APL Apollo </p>
                                <p className='md:text-[40px]  font-medium font-roboto text-white'>Lume Tuff</p>
                                <p className='md:text-[17px]  md:pr-[30px] pr-[8px] text-[#D2D2D2] font-roboto'>
                                    APL Apollo Lume tuff metal roofs coated with aluminum and zinc provide Excellent protection in high-temperature, highly humid, and marine environments. It offers superior surface protection compared to galvanized roofs
                                </p>
                            </div>

                        </div>

                        <div className='flex flex-col gap-[16px] w-[400px]  h-[890px]  pt-[192px] '>

                            <div data-aos="flip-left" className='btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group'>
                                <span className="w-0 h-0 rounded bg-white/30 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                                <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10"> </span>
                                <img src={img3} alt='Product_Image' className='w-full h-full' />
                            </div>

                            <div data-aos="flip-left" className='text-start flex flex-col md:gap-[10px] gap-[3px]'>
                                <p className='text-[20px] font-roboto text-white'>APL Apollo </p>
                                <p className='text-[40px]  font-medium font-roboto text-white'>Rakshak</p>
                                <p className='text-[17px]  md:pr-[30px] pr-[8px] text-[#D2D2D2] font-roboto'>
                                    APL Apollo Rashak sheets are satin-finished aluminium and zinc (Al-Zn) roofing solution, that offers exceptional corrosion protection and extends their lifespan for Residential Roofs, Industrial Sheds, Warehouse Sheds & Commercial Buildings.
                                </p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


            {/* Swiperr for mobile product section */}

            <div className='md:hidden block'>
                <div>
                    <div className='mt-[20px]'>
                        <p className='text-[#000000] text-[35px] font-roboto text-center font-extrabold'>
                            Our Most Selled Products
                        </p>
                    </div>


                    <Swiper
                        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                        spaceBetween={8}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        className='w-[350px] h-fit  mt-[16px]'

                    >

                        <div className='flex justify-center items-center '>

                            <SwiperSlide className='w-[100px] h-[100px]'>
                                <div>
                                    <img src={img1} alt="apl products" className='w-full h-full rounded shadow-[#00000040]' />
                                    <div className='text-start flex flex-col  gap-[2px] pb-[6px] '>
                                        <p className='text-[10px] font-roboto'>APL Apollo </p>
                                        <p className='text-[15px] font-medium font-roboto'>Rooftuff</p>
                                        <p className='text-[10px] md:pr-[30px] pr-[8px] text-[#4C4C4C] font-roboto'>APL Apollo Roof tuff is a range of color coated Alu-zinc
                                            roofing sheets for high corrosion resistive,
                                            longer life and excellent aesthetics purposes</p>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='w-[100px] h-[100px]'>

                                <div>
                                    <img src={img2} alt="apl products" className='w-full h-full rounded shadow-[#00000040]' />
                                    <div className='text-start flex flex-col  gap-[2px] pb-[6px]'>
                                        <p className='text-[10px] font-roboto'>APL Apollo </p>
                                        <p className='text-[15px] font-medium font-roboto'>Lume Tuff</p>
                                        <p className='text-[10px] md:pr-[30px] pr-[8px] text-[#4C4C4C] font-roboto'>APL Apollo Lume tuff metal roofs coated with aluminum and zinc provide Excellent protection in high- temperature, high precipitation and marine
                                            Environment. They offer superior surface protection compared to galvanized roofs
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='w-[100px] h-[100px]'>
                                <div>

                                    <img src={img3} alt="apl products" className='w-full h-full rounded shadow-[#00000040]' />
                                    <div className='text-start flex flex-col  gap-[2px] pb-[6px]'>
                                        <p className=' text-[10px] font-roboto'>APL Apollo </p>
                                        <p className='text-[15px] font-medium font-roboto'>Rakshak</p>
                                        <p className='text-[10px]  pr-[8px] text-[#4C4C4C] font-roboto'>APL Apollo Rashak sheets are satin finished aluminium and zinc (Al-Zn) roofing solution,
                                            which offers exceptional corrosion protection and extends their lifespan for Residential Roofs, Industrial Sheds, Warehouse Sheds & Commercial Buildings.
                                        </p>
                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide className='w-[100px] h-[100px]'>

                                <div>
                                    <img src={img2} alt="apl products" className='w-full h-full rounded shadow-[#00000040]' />
                                    <div className='text-start flex flex-col  gap-[2px] pb-[6px]'>
                                        <p className=' text-[10px] font-roboto'>APL Apollo Lume Tuff</p>
                                        <p className='text-[15px] font-medium font-roboto'>Lume Tuff</p>
                                        <p className='text-[10px] md:pr-[30px] pr-[8px] text-[#4C4C4C] font-roboto'>APL Apollo Lume tuff metal roofs coated with aluminum and zinc provide Excellent protection in high- temperature, high precipitation and marine
                                            Environment. They offer superior surface protection compared to galvanized roofs
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide className='w-[100px] h-[100px]'>
                                <div>
                                    <img src={img1} alt="apl products" className='w-full h-full rounded shadow-[#00000040]' />
                                    <div className='text-start flex flex-col  gap-[2px] pb-[6px]'>
                                        <p className=' text-[10px] font-roboto'>APL Apollo Roof Tuff</p>
                                        <p className='text-[15px] font-medium font-roboto'>Rooftuff</p>
                                        <p className='text-[10px] md:pr-[30px] pr-[8px] text-[#4C4C4C] font-roboto'>APL Apollo Roof tuff is a range of color coated Alu-zinc
                                            roofing sheets for high corrosion resistive,
                                            longer life and excellent aesthetics purposes</p>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className='w-[100px] h-[100px]'>

                                <div>
                                    <img src={img2} alt="apl products" className='w-full h-full rounded shadow-[#00000040]' />
                                    <div className='text-start flex flex-col  gap-[2px] pb-[6px]'>
                                        <p className=' text-[10px] font-roboto'>APL Apollo Lume Tuff</p>
                                        <p className='text-[15px] font-medium font-roboto'>Lume Tuff</p>
                                        <p className='text-[10px] md:pr-[30px] pr-[8px] text-[#4C4C4C] font-roboto'>APL Apollo Lume tuff metal roofs coated with aluminum and zinc provide Excellent protection in high- temperature, high precipitation and marine
                                            Environment. They offer superior surface protection compared to galvanized roofs
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </div>

                    </Swiper>
                </div>

            </div>

            {/* Raipur Project */}

            <div className='md:block hidden '>

                <div className='flex justify-evenly gap-[100px]'>
                    <div
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        className='md:w-[750px] w-[400px] md:pl-[40px]'>
                        <div className=' flex flex-col gap-[20px]  md:mt-[140px] p-2'>
                            <p className='uppercase font-roboto  md:text-[55px] text-[20px] text-start font-'>
                                Explore APL BUILDING
                                RAIPUR Plant
                            </p>
                            <p className='md:text-[25px] text-[15px] text-[#5C5C5C] font-roboto font-medium'>
                                This project first marked its construction in FY 20-21 with the name "APL Apollo Building Products Pvt Ltd" (ABPL is a wholly owned subsidiary of APL Apollo Tubes Ltd ) which is India's largest structural steel tube manufacturer. Our plant is situated in Raipur and is manufacturing various unique products. Explore our About Us section to know more about ABPL.
                            </p>

                            <Link to={'aboutus'}>
                                <button type='button'
                                    className='w-[218px] h-[56px]  inline-flex  bg-[#FE6910]/100 hover:bg-gray-900 duration-300    justify-evenly items-center md:mt-[56px] mt-[20px]'>
                                    <div className='gap-[30px]   inline-flex items-center justify-center    font-bold text-white transition-all duration-200    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>
                                        <p className='text-[17px] font-roboto font-medium text-white '>About Us</p>
                                        <BsArrowRight size={30} className='text-white  duration-300' />
                                    </div>
                                </button>
                            </Link>

                        </div>
                    </div>

                    <div
                        data-aos="fade-down"
                        data-aos-duration="1200"

                        className='md:w-[600px] w-[300px] md:h-[500px] md:pt-[130px] md:pr-[100px] flex justify-center items-center'>
                        <div className='flex flex-col justify-center pb-[50px] '>

                            <div>
                                <p className='text-[#0F0F0F] md:text-[60px] text-[25px] font-roboto font-extrabold'> 1 Mn Ton</p>
                                <p className='md:text-[25px] text-[15px] text-[#4E4E4E] font-roboto font-semibold'>Current Annual Capacity</p>
                            </div>

                            <div>
                                <p className='text-[#0F0F0F] md:text-[60px] text-[25px] font-roboto font-extrabold'>0.3 Mn Ton</p>
                                <p className='md:text-[25px] text-[15px] text-[#4E4E4E] font-roboto font-semibold'>Additional Expansion in Place</p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            {/* Raipur Project for mobile */}

            <div className='md:hidden block  mt-[30px]'>
                <div>

                    <div data-aos="fade-up"
                        data-aos-duration="1200"
                        className='flex gap-[10px] pt-[10px]  pl-[15px] pr-[15px]'>

                        <div>
                            <p className='text-[#0F0F0F]  text-[33px] font-roboto font-extrabold'> 1 Mn Ton</p>
                            <p className='md:text-[20px] text-[15px] text-[#4E4E4E] font-roboto font-semibold'>Current Annual Capacity</p>
                        </div>

                        <div>
                            <p className='text-[#0F0F0F]  text-[33px] font-roboto font-extrabold'>0.3 Mn Ton</p>
                            <p className='md:text-[20px] text-[15px] text-[#4E4E4E] font-roboto font-semibold'>Additional Expansion in Place</p>
                        </div>

                    </div>

                    <div data-aos="fade-down"
                        data-aos-duration="1200"
                    >
                        <p className='uppercase font-roboto   text-[25px]  text-center font-bold mt-[25px]	'>ABOUT  APL BUILDING  RAIPUR PROJECT</p>

                        <p className=' text-[15px] text-[#5C5C5C] font-roboto font-medium pt-[10px] pl-[10px] pr-[10px] mt-[10px]'>Project started in FY 20-21, APL Apollo Building Products Pvt Ltd.
                            (ABPL) a wholly owned subsidiary of APL Apollo Tubes Ltd.
                            (APL) at Village Simga, Baloda bazar district in Chhattisgarh (almost 50 Km from Raipur Airport).
                        </p>
                    </div>
                </div>

            </div>

            {/* Career APL */}
            <div className='md:pl-[40px] md:pr-[40px]'>
                <div className='relative rounded  max-w-full md:h-[623px] h-[350px] mx-auto bg-gradient-to-b from-[#005C61]/60 to-[#000000] md:mt-[110px] mt-[60px]'>

                    <div data-aos="fade-up-right" className='md:w-[350px] w-[130px] md:h-[303px] h-fit absolute right-0'>
                        <img src={im1} alt="apl products" />
                    </div>

                    <div data-aos="fade-up-right" className='md:block hidden w-[411px] h-[354px] absolute top-0 left-[527px]'>
                        <img src={im2} alt="apl products" />
                    </div>

                    <div data-aos="fade-up-right" className='md:block hidden md:w-[370px] w-[100px] md:h-[279px] h-[100px] absolute md:right-[100px] right-0  md:bottom-[40px] bottom-0'>
                        <img src={im3} alt="apl products" />
                    </div>

                    <div className='md:pl-[70px] pl-[20px] md:pt-[40px] pt-[50px] '>
                        <div className='md:w-[400px] w-fit '>
                            <p data-aos="fade-up-right" className='md:text-[55px] text-[25px] font-roboto font-bold text-white'>
                                Careers at
                                APL Apollo <br className='md:hidden' /> Building Products Pvt Ltd.

                            </p>
                        </div>

                        <p data-aos="fade-up-right" className='pt-[20px] md:text-[25px] text-[16px] font-roboto text-white'>We invite you to supercharge your potential. </p>
                        <p data-aos="fade-up-right" className=' md:text-[25px] text-[16px] font-roboto text-white'> Find what inspires and drives you. Find your spark.</p>

                        <Link to={'#'}>
                            <button type='button'
                                className='w-[218px] h-[56px]  inline-flex     bg-[#FE6910]/100 hover:bg-gray-900   duration-300 justify-evenly items-center md:mt-[56px] mt-[20px]'>
                                <div className='gap-[30px]   inline-flex items-center justify-center    font-bold text-white transition-all duration-200    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>
                                    <p className='text-[17px] font-roboto font-medium text-white '>Career APL</p>
                                    <BsArrowRight size={30} className='text-white' />
                                </div>
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

            {/* get in touch */}

            <div
                data-aos="fade-up"
                data-aos-duration="1200"
                className='flex flex-col md:items-center text-center justify-center md:mt-[53px] mt-[20px]'>
                <div>
                    <p className='md:text-[55px] md:pl-0 pl-[40px] text-[40px] font-bold font-roboto text-[#4C4C4C] '>Get in  <span className='text-[#FE6910] pl-[10px]'> touch </span> </p>
                </div>
                <p className='md:text-[25px] text-[15px] md:pl-0 pl-2 md:pr-0 pr-2  text-[#616161]  font-roboto font-medium break-all'>Get in touch to learn more about our automation platform and how we can help you
                    achieve your business goals.</p>

                <div className='md:pl-0 pl-[20px]'>
                    <Link to={'/contactus'}>
                        <button type='button'
                            className='w-[218px] h-[56px]  inline-flex  bg-[#FE6910]/100 hover:bg-gray-900  duration-300 justify-evenly items-center md:mt-[56px] mt-[20px]'>
                            <div className='gap-[30px]   inline-flex items-center justify-center    font-bold text-white transition-all duration-200    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>
                                <p className='text-[17px] font-roboto font-medium text-white '>Contact Us</p>
                                <BsArrowRight size={30} className='text-white ' />
                            </div>
                        </button>
                    </Link>
                </div>
            </div>

            {/* Footer */}
            <Footer />




        </>
    )
}

export default Home