import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';
import { AiFillPrinter } from 'react-icons/ai';

const Grievance = () => {
    return (
        <>




            <div className='flex  md:justify-center items-center gap-[60px]'>

                <div className='flex flex-col gap-[28px]'>

                    <div className='border  md:w-[1000px] w-full md:h-[35px] h-[56px] border-[#EFEFEF] bg-[#EFEFEF]  rounded-[5px] items-center flex md:pl-[44px] p-1'>
                        <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase '>For any Investor Grievances, you may contact:</h1>
                    </div>

                    <div className='flex md:flex-row flex-col gap-[62px] justify-between'>

                        <div className='md:w-[450px] w-full md:h-[400px] h-fit border  rounded-[5px] shadow-xl shadow-[#0000000D] flex flex-col gap-[35px] p-4' >
                            <h1 className='md:text-[22px] text-[19px] font-roboto font-semibold text-center'>Company Secretary</h1>

                            <div className='flex gap-[21px] md:text-[20px] text-[16px] '>
                                <BsFillTelephoneFill size={20} className='text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878]  font-roboto font-medium'>Tel : 022-4302 5555</p>
                            </div>

                            <div className='flex gap-[21px] md:text-[20px] text-[16px] '>
                                <FaLocationDot size={30} className='text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878]  font-roboto font-medium'>APL Apollo Tubes Limited36 Kaushambi,
                                    Near Anand Vihar Terminal,
                                    Delhi NCR- 201010</p>
                            </div>

                            <div className='flex gap-[21px] md:text-[20px] text-[16px] '>
                                <IoMdMail className=' text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878]  font-roboto font-medium'>Email : corporate@sbicaptrustee.com</p>
                            </div>

                        </div>


                        <div className='md:w-[450px] w-full  md:h-[400px] h-fit border  rounded-[5px] shadow-xl shadow-[#0000000D] flex flex-col gap-[35px] p-4' >
                            <h1 className='text-[22px] font-roboto font-semibold text-center '>Registrar & Transfer Agent</h1>

                            <div className='flex gap-[21px] items-center'>
                                <BsFillTelephoneFill className='text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878]  font-roboto font-medium'>Tel: 011-42390725, 011-42390783</p>
                            </div>

                            <div className='flex gap-[21px] items-center'>
                                <FaLocationDot className='text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878] md:text-[20px] text-[] font-roboto font-medium'>Abhipra Capital Limited GF- Abhipra Complex,
                                    Dilkhush Industrial Area A-387, G.T. Karnal Road
                                    Azadpur, Delhi-110033
                                </p>
                            </div>

                            <div className='flex gap-[21px] items-center'>
                                <AiFillPrinter className='text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878] text-[20px] font-roboto font-medium'>Fax: 011-42390830</p>
                            </div>

                            <div className='flex gap-[21px] items-center'>
                                <IoMdMail className='text-[#F47E32] md:text-[30px]' />
                                <p className='text-[#787878] text-[20px] font-roboto font-medium'>Email: rta@abhipra.com</p>
                            </div>

                        </div>
                    </div>

                </div>



            </div>



        </>
    )
}

export default Grievance