import React from 'react'
import { FaGreaterThan } from 'react-icons/fa';
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import Footer from '../homecomponent/Footer';
import banner from '../../assest/contactImage/banner4.webp';
import navrang from '../../assest/contactImage/navrang.webp';
import Navbar from '../homecomponent/Navbar';
import product4 from '../../assest/contactImage/product4.webp';
import swa2 from '../../assest/productImage/swa2.webp';


import pa7 from '../../assest/contactImage/pa7.webp';
import pa8 from '../../assest/contactImage/pa8.webp';
import pa9 from '../../assest/contactImage/pa9.webp';




import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";


import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Product4 = () => {

    const [open, setOpen] = React.useState(1);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    return (
        <>

            <div className='max-w-[1620px] md:h-[720px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div

                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            className=' bg-[#00ACB6]/70 md:ml-[250px] md:mr-[250px]'>
                            <div className='flex flex-col justify-center items-center mt-[130px] gap-[10px] p-[30px] '>
                                <div className='md:pb-[30px] font-black md:text-[60px] text-[30px] text-white text-center uppercase'>Apollo Exclusive
                                </div>

                                <h1 className='text-[24px] text-[#FFFFFF] font-semibold font-roboto text-justify'>Apollo- Navrang</h1>
                                <div className='w-[176px] h-[2px] bg-white mt-[70px]'></div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>


            {/* for mobile */}
            <div className='md:hidden block flex-col '>
                <div className='mt-[10px] pl-[10px] pr-[10px]'>
                    <h1 className='text-[25px] font-roboto font-extrabold md:text-start text-center md:mt-0 mt-[20px]'>Apollo Navrang-Steel Coil and Sheets</h1>
                </div>
                {/* <div className='w-[154px] h-[20px] items-center'>
                    <img src={navrang} alt='navrang' />
                </div> */}
            </div>


            {/* Text area */}

            <div className='flex md:flex-row flex-col md:gap-[70px]  md:mt-[72px] mt-[30px]  md:pl-[50px] pl-[10px] pr-[10px]  '>

                <div className=' md:h-[580px] h-[300px] md:w-[45%]  flex justify-center shadow-xl shadow-[#e0e0e0] '>
                    <img src={product4} alt='apl navrang' className='w-[600px]' />

                </div>


                <div className=' md:w-1/2 w-full flex flex-col gap-[20px] md:pr-[50px]'>

                    {/* <div className='w-[354px] h-[154px] md:block hidden'>
                        <img src={navrang} alt='' />
                    </div> */}

                    <div className='md:block hidden'>
                        <h1 className='text-[35px] font-roboto font-extrabold'>Apollo Navrang-Steel Coil and Sheets</h1>
                    </div>

                    <i className='text-[#696969] font-roboto font-bold md:text-[20px] text-[17px]  md:mt-0 mt-[40px] '>“WORLD’S FIRST NARROW AND THICKER COLOR COATED SHEETS”</i>



                    <div>
                        <p className=' text-[#4E4E4E] md:text-[20px] text-[17px] font-roboto  '>
                            Apollo Navrang is another example of futuristic technology being put to good use to create
                            building materials that combine aesthetic appeal with strength and longevity and add beauty
                            without affecting the functionality of the structure. Apollo Navrang is yet another breakthrough
                            in the world of building materials from India’s pioneers in the manufacturing and development of
                            structural steel solutions. It is a designer concept of narrow width, high thickness, color-coated
                            coils and sheets. Apollo’s Go Green concept aims to replace wood with steel sections in order to save  the planet.
                        </p>

                    </div>


                    <div>
                        <div className='flex  '>

                            <h1 className='text-[#F47E32] md:text-[21px] text-[17px] font-roboto font-bold p-2 border-b-2 border-[#F47E32] md:w-[315px]  text-center'>Specifications</h1>

                        </div>
                    </div>

                    <ul>
                        <li className='text-[#000000] md:text-[18px] text-[15] font-medium font-roboto'>• Width: 300–750mm</li>
                        <li className='text-[#000000] md:text-[18px] text-[15] font-medium font-roboto'>• Thickness: 0.3–3.0 mm</li>
                        <li className='text-[#000000] md:text-[18px] text-[15] font-medium font-roboto'>• Marble, stone finish, and many more</li>
                        <li className='text-[#000000] md:text-[18px] text-[15] font-medium font-roboto'>• Color Options: Light Blue, Off-White, Brick Red, Green, and Grey with designer <br /> finishes like wood, marble, stone finish, and many more</li>
                    </ul>

                </div>

            </div>




            <div className='md:pl-[130px] pl-[10px] md:pr-[130px] pr-[10px] flex  flex-col   md:mt-[100px] mt-[30px]'>

                <Accordion open={open === 1} style={{ padding: '0px' }}>

                    <div className='flex flex-col  gap-[10px] '>
                        <div className='bg-[#E0E0E0] flex items-center  '>

                            <AccordionHeader onClick={() => handleOpen(1)} className='text-[#F47E32] md:text-[18px] text-[15px] md:font-bold font-roboto md:pl-[33px] pl-3 ' >BRAND PERSONALITY:</AccordionHeader >
                            <FaGreaterThan size={15} onClick={() => handleOpen(1)} className={open === 1 ? 'mr-[33px] rotate-90 transition-transform duration-300 ease-in-out' : 'mr-[33px] ease-in-out'} />
                        </div>
                        <AccordionBody className=' text-[#696969] md:text-[19px] text-[15px] font-roboto font-medium'>These pre-galvanized/Alu Zinc sheets, also known as Galvalume sheets, have a wide range of applications, including warehouse sheds, residential and commercial building rooftops, open door frames, PEB Sheds, and infrastructure industry applications such as roof panels, bus roofing and inner body panels, shutter patti, purlins, solar panel pedestals, solar panel frames, ducting, dish antennas, automobile components, home appliances and drums, car crash barriers, air conditioners, dish antennas, refrigerators, etc.</AccordionBody>

                    </div>

                </Accordion>

                <Accordion open={open === 2}>
                    <div className='flex flex-col  gap-[10px]  md:mt-[10px] mt-[5px]'>
                        <div className='bg-[#E0E0E0] flex items-center  '>

                            <AccordionHeader onClick={() => handleOpen(2)} className='text-[#F47E32] md:text-[18px] text-[15px] font-bold font-roboto  md:pl-[33px] pl-3 ' >ABOUT APOLLO NAVRANG PRODUCT RANGE:</AccordionHeader>
                            <FaGreaterThan size={15} onClick={() => handleOpen(2)} className={open === 2 ? 'mr-[33px] rotate-90 transition-transform duration-300 ease-in-out' : 'mr-[33px] ease-in-out'} />

                        </div>
                        <AccordionBody className=' text-[#696969] md:text-[19px] text-[15px] font-roboto font-medium'>Steel structural products manufactured by APL Apollo are used in commercial, industrial, and infrastructure applications. The company produces and distributes structural steel in India through a large network. With sustainable technologies, it offers a wide range of steel products. Navrang is an innovative steel building product made up of narrow, thick color-coated coils and sheets. It was introduced as a replacement for wood for housing and commercial applications. They are also known as galvalume sheets produced from the continuous galvanizing/Alu Zinc process.</AccordionBody>

                    </div>

                </Accordion>


                <Accordion open={open === 3}>
                    <div className='flex flex-col  gap-[10px] md:mt-[10px] mt-[5px]'>
                        <div className='bg-[#E0E0E0] flex items-center  '>

                            <AccordionHeader onClick={() => handleOpen(3)} className='text-[#F47E32] md:text-[18px] text-[15px] font-bold font-roboto  md:pl-[33px] pl-3  ' >BEST FEATURES AND PRODUCTS OF APOLLO NAVRANG:</AccordionHeader>
                            <FaGreaterThan size={15} onClick={() => handleOpen(3)} className={open === 3 ? 'mr-[33px] rotate-90 transition-transform duration-300 ease-in-out' : 'mr-[33px] ease-in-out'} />

                        </div>

                        <AccordionBody className=' space-y-1'>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'>Provide a smooth surface and unique appearance similar to matte galvanized steel.</li>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'>It has an anti-fingerprint coating to make it look better and more shiny.</li>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'>It offers high tensile strength and long-lasting durability.</li>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'>Get the surface treated as per ROHS (Restriction on Hazardous Substances) norms.</li>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'> Able to withstand high temperatures up to 315 degrees Celsius and heat without discoloration.</li>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'>have a wider thickness with a tighter tolerance ability.</li>
                            <li className='md:text-[19px] text-[15px] text-[#696969] font-roboto font-medium'>Available Range</li>
                        </AccordionBody>
                    </div>

                </Accordion>

                <Accordion open={open === 4}>
                    <div className='flex flex-col md:gap-[17px] gap-[10px] md:mt-[10px] mt-[5px]'>
                        <div className='bg-[#E0E0E0] flex items-center  '>

                            <AccordionHeader onClick={() => handleOpen(4)} className='text-[#F47E32] md:text-[18px] text-[15px] font-bold font-roboto  md:pl-[33px] pl-3  ' >ABOUT APOLLO NAVRANG PRODUCT RANGE:</AccordionHeader>
                            <FaGreaterThan size={15} onClick={() => handleOpen(4)} className={open === 4 ? 'mr-[33px] rotate-90 transition-transform duration-300 ease-in-out' : 'mr-[33px] ease-in-out'} />

                        </div>
                        <AccordionBody className=' text-[#696969] md:text-[19px] text-[15px] font-roboto font-medium'>
                            Yogiji Digi is also used to make the color-coating line. The stainless steel surface is primed and finished in a catenary-style oven with four zones. Additionally, there is a fully-fledged chemical-reducing portion with flex-type inline tension leveling. The continuous color coating line has a higher thickness. The coating and cleaning process produces premium-colored coils that are safe for storage and shipping. Applications: For durable building materials, such as bus roofing, roof panels, solar panel pedestals, solar panel frames, door frames, etc., colored steel coils are ideal.
                        </AccordionBody>
                        <AccordionBody className=' text-[#696969] md:text-[19px] text-[15px] font-roboto font-medium'>
                            Applications: For durable building materials, such as bus roofing, roof panels, solar panel pedestals, solar panel frames, door frames, etc., colored steel coils are ideal.                        </AccordionBody>

                    </div>
                </Accordion>
            </div>



            {/* Product Application carousoles */}

            <div className='md:block hidden'>
                <div className='md:mt-[80px] mt-[40px]'>
                    <h1 className='text-[#000000] text-[55px] font-roboto text-center font-extrabold'>
                        PRODUCT APPLICATIONS
                    </h1>
                </div>


                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={60}
                    slidesPerView={4}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className='w-[1400px] h-[300px]  mt-[76px]'

                >

                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa8} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa9} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa7} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa8} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa9} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa8} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa9} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* for  mobile responsive */}

            <div className='md:hidden block'>
                <div className='mt-[47px]'>
                    <h1 className='text-[#000000] text-[25px] font-roboto text-start pl-[10px] font-extrabold'>
                        PRODUCT APPLICATIONS
                    </h1>
                </div>


                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
                    spaceBetween={10}
                    slidesPerView={3}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        type: 'fraction',
                    }}

                    scrollbar={{ draggable: true }}
                    className='w-full h-fit  mt-[32px] '

                >

                    <div className='flex justify-center items-center'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa7} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa8} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa9} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa7} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa8} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa9} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa7} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* Structure careousel */}
            <div className='flex md:flex-row flex-col justify-between md:mt-[100px] my-[50px]'>

                <div className='flex flex-col md:pl-[50px] pl-[10px]  gap-[10px]'>

                    <h1 className='font-roboto md:text-[50px] text-[25px] font-extrabold'>STRUCTURAL IN OUR WORLD</h1>

                    <div className=''>
                        <button
                            className='text-[#F47E32]  flex justify-center font-roboto text-[15px] hover:bg-gray-900 hover:text-white duration-300 
        font-semibold items-center gap-[10px] w-[219px] h-[56px] border-2 border-[#F47E32] hover:border-white'>
                            <span>DOWNLOAD BROCHURE </span><BsBoxArrowInUpRight size={20} /> </button>
                    </div>
                </div>
                <div className='md:mt-[2px] mt-[50px] md:pl-[0px] pl-[10px]  md:pr-0 pr-[10px]'>
                    <Swiper
                        slidesPerView={2}
                        centeredSlides={true}
                        spaceBetween={30}
                        scrollbar={{ draggable: true }}
                        pagination={{
                            type: 'fraction',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination, Autoplay, Navigation, Scrollbar]}
                        className='md:w-[900px] w-full md:h-[350px] h-fit'
                    >
                        <div >

                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>



                        </div>
                    </Swiper>

                </div>

            </div>



            <Footer />

        </>
    )
}

export default Product4;