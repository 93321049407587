import React from 'react'
import Navbar from '../../../homecomponent/Navbar';
import banner from '../../../../assest/contactImage/banner2.webp';
import Footer from '../../../homecomponent/Footer';
import { FaFilePdf } from "react-icons/fa6";
import finanicalPdf from '../../../../assest/Website/Financial results/FNR_sept_2023.pdf';
import ABPLFinancial from '../../../../assest/Website/Financial results/ABPL Financials.pdf';
import DECEMBER2023 from '../../../../assest/Website/Financial results/December financial.pdf';
import SEPTEMBER2023 from '../../../../assest/Website/Financial results/sept financials.pdf';


const FinancialResult = () => {
    return (

        <>


            <div className='max-w-[1920px] md:h-[620px] h-[300px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[110px] mt-[50px] gap-[16px]'>
                            <div className='pb-[30px] font-black  md:text-[70px] text-white text-center uppercase'>Financial Result
                            </div>

                            {/* <h1 className='md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto  text-center'>Coming soon </h1> */}
                            <div className='w-[176px] h-[2px] bg-white '></div>

                        </div>

                    </div>
                </div>

            </div>



            {/* <div className='flex flex-col mt-[50px] mb-[200px] items-center md:gap-[33px] gap-[10px]'>

                <div className='border-b-2 border-gray-300 md:w-[800px] w-full md:h-[35px] h-[56px]  '>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Financial Year 2023</h1>
                </div>

                <div className=' md:w-[800px] w-full md:h-[30px] h-[56px]  p-1'>

                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-[50px]'>
                            <img src={pdflogo} className='' />
                            <h1 className='text-[#3D3D3D] md:text-[14px] text-[15px] font-bold font-roboto uppercase'>
                                Notice of board meeting dated 30 October 2023
                            </h1>
                        </div>
                        <h1 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto uppercase'> <a href={finanicalPdf}>Download</a> </h1>
                    </div>


                    <div className='flex items-center justify-between mt-8'>

                        <div className='flex gap-[50px] items-center  '>
                            <img src={pdflogo} className='' />
                            <h1 className='text-[#3D3D3D] md:text-[14px] text-[15px] font-bold font-roboto uppercase'>
                                Notice of board meeting dated September 2023
                            </h1>
                        </div>

                        <h1 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto uppercase'><a href={ABPLFinancial}>Download</a></h1>

                    </div>


                    <div className='flex items-center justify-between mt-8'>

                        <div className='flex gap-[50px] items-center  '>
                            <img src={pdflogo} className='' />
                            <h1 className='text-[#3D3D3D] md:text-[14px] text-[15px] font-bold font-roboto uppercase'>
                                Notice of board meeting dated December 2024
                            </h1>
                        </div>

                        <h1 className='md:text-[14px] text-[#00ACB6] text-[15px]  font-roboto uppercase'><a href={ABPLFinancial}>Download</a></h1>

                    </div>

                </div>
            </div> */}




            <div className='flex flex-col justify-between items-center mt-[100px] mb-[100px] p-1'>
                <table className="md:min-w-[1000px] md:max-w-[1000px] w-full border border-gray-300">
                    <thead>
                        <tr className="border-b bg-gray-100 text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase">
                            <th className="py-2 px-4 border-r">Date</th>
                            <th className="py-2 px-4 border-r">Title</th>
                            <th className="py-2 px-4 border-r">Download</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr className=' text-[#3D3D3D] md:text-[14px] text-[13px] font-bold font-roboto uppercase'>
                            <td className="py-2 px-4 border-r text-center h-[55px]">DECEMBER 2023</td>
                            <td className="py-2 px-4 border-r text-center h-[55px]">Financial results</td>
                            <td className="py-2 px-4 border-r text-center h-[55px]">
                                <button className='border border-[#00ACB6] p-1 md:pl-9 pl-2 md:pr-9 pr-2 shadow-inner text-[#00ACB6] font-light md:text-[14px] text-[13px]  font-roboto uppercase'> <a target="_blank" href={DECEMBER2023}> download </a> </button>
                            </td>
                        </tr>



                        {/* <tr className="border-b text-[#3D3D3D] md:text-[14px] text-[13px] font-bold font-roboto uppercase ">
                            <td className="py-2 px-4 border-r text-center h-[55px]">30 OCTOBER 2023</td>
                            <td className="py-2 px-4 border-r text-center h-[55px]">outcome OF ABPL Financials</td>
                            <td className="py-2 px-4 border-r text-center h-[55px]">
                                <button className='border border-[#00ACB6] p-1 md:pl-9 pl-2 md:pr-9 pr-2 shadow-inner text-[#00ACB6] font-light md:text-[14px] text-[13px]  font-roboto uppercase'><a target="_blank" href={finanicalPdf}>Download</a></button>
                            </td>
                        </tr> */}

                        <tr className='bg-gray-100 text-[#3D3D3D] md:text-[14px] text-[13px] font-bold font-roboto uppercase'>
                            <td className="py-2 px-4 border-r text-center h-[55px]">SEPTEMBER 2023</td>
                            <td className="py-2 px-4 border-r text-center h-[55px]"> Financial results</td>
                            <td className="py-2 px-4 border-r text-center h-[55px]">
                                <button className='border border-[#00ACB6] p-1 md:pl-9 pl-2 md:pr-9 pr-2 shadow-inner text-[#00ACB6] font-light md:text-[14px] text-[13px]  font-roboto uppercase'><a target="_blank" href={SEPTEMBER2023}> download </a></button>
                            </td>
                        </tr>




                    </tbody>
                </table>
            </div>




            <Footer />
        </>


    )
}

export default FinancialResult