import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';


const Debenture = () => {
    return (
        <>
            <div className='md:pl-[300px]'>
                <div className='md:w-[450px] w-full md:h-[400px] border  rounded-[5px] shadow-xl shadow-[#0000000D] flex flex-col md:gap-[35px] gap-[25px] p-4' >
                    <h1 className='md:text-[22px] text-[20px] font-roboto font-semibold text-center'>SBICAP Trustee Company Ltd.</h1>

                    <div className='flex gap-[21px] md:text-[20px] text-[16px] items-center '>
                        <BsFillTelephoneFill className='text-[#F47E32] md:text-[30px] ' />
                        <p className='text-[#787878]  font-roboto font-medium'>Tel : 022-4302 5555</p>
                    </div>

                    <div className='flex gap-[21px] md:text-[20px] text-[16px] items-center'>
                        <FaLocationDot className='text-[#F47E32] md:text-[40px]' />
                        <p className='text-[#787878]  font-roboto font-medium'>APL Apollo Tubes Limited36 Kaushambi,
                            Near Anand Vihar Terminal,
                            Delhi NCR- 201010</p>
                    </div>

                    <div className='flex gap-[21px] md:text-[20px] text-[16px] items-center'>
                        <IoMdMail className='text-[#F47E32] md:text-[30px]' />
                        <p className='text-[#787878]  font-roboto font-medium'>Email : corporate@sbicaptrustee.com</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Debenture