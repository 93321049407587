import Navbar from "./Navbar";
import banner from '../../assest/homeImage/banner.webp';



function Header() {
    return (
        <>
            <div className=" max-w-[1900px] mx-auto  md:h-[900px] h-[500px] ">
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full'>

                        <Navbar />


                        {/* Text Area */}

                        <div className='flex flex-col'>

                            <div className='mt-[90px]'>
                                <h1 className='uppercase md:text-[24px] text-[17px] text-white font-bold font-roboto text-center'>REVOLUTIONIZING THE WORLD OF TUBES WITH</h1>

                            </div>
                            <div className='flex justify-center md:pl-0 pl-[20px] md:pr-0 pr-[20px] '>

                                <fieldset className='md:w-[1000px] w-full border-[6px] border-[#F47E32] rounded-xl'>
                                    <legend className='pb-[30px] font-black md:text-[135px] text-[50px] text-white text-center'>𝙉𝘼𝙔𝙄 𝙎𝙊𝘾𝙃
                                    </legend>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Header;