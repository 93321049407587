import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import banner from '../../assest/contactImage/banner4.webp';
import Navbar from '../homecomponent/Navbar';

import swa2 from '../../assest/productImage/swa2.webp';
import pa4 from '../../assest/productImage/LUMETUFF/acd.webp';
import pa5 from '../../assest/productImage/LUMETUFF/file_cabinet.webp';
import pa6 from '../../assest/productImage/LUMETUFF/pr.webp';
import pa7 from '../../assest/productImage/LUMETUFF/ss.webp';


import lumetuff from '../../assest/productImage/LUMETUFF/BG1.webp';
import icon1 from '../../assest/productImage/LUMETUFF/icon1.1.webp';
import icon2 from '../../assest/productImage/LUMETUFF/icon1.2.webp';
import icon3 from '../../assest/productImage/LUMETUFF/icon1.3.webp';
import icon4 from '../../assest/productImage/LUMETUFF/icon1.4.webp';

import icon5 from '../../assest/productImage/LUMETUFF/icon1.webp';
import icon6 from '../../assest/productImage/LUMETUFF/icon2.webp';
import icon7 from '../../assest/productImage/LUMETUFF/icon3.webp';
import icon8 from '../../assest/productImage/LUMETUFF/icon4.webp';
import icon9 from '../../assest/productImage/LUMETUFF/icon5.webp';

import ignuity from '../../assest/productImage/LUMETUFF/ig.webp';



import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Footer from '../homecomponent/Footer';


const Product2 = () => {
    return (
        <>

            <div className='max-w-[1620px] md:h-[720px] h-[500px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            className=' bg-[#00ACB6]/70 md:ml-[250px] md:mr-[250px]'>
                            <div className='flex flex-col justify-center items-center mt-[130px] gap-[10px] p-[30px] '>
                                <div className='md:pb-[10px] font-black md:text-[60px] text-[30px] text-white text-center uppercase'>Apollo Exclusive
                                </div>

                                <h1 className='text-[24px] text-[#FFFFFF] font-semibold font-roboto text-justify'>Apollo Lume Tuff</h1>
                                <div className='w-[176px] h-[2px] bg-white mt-[40px]'></div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/*  */}





            {/* for mobile */}
            <div className='md:hidden block   flex-col '>
                <div className='mt-[10px] pl-[10px] pr-[10px]'>
                    <p className='text-[25px] font-roboto font-extrabold text-center'>APL Apollo Lume Tuff</p>
                </div>

            </div>



            <div className='flex md:flex-row flex-col md:gap-[70px] gap-[11px]  mt-[50px] md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px]'>

                <div className='md:w-[45%] w-full md:h-[580px] h-[300px] mt-[10px] shadow-2xl shadow-[#00000026]  flex justify-center items-center '>
                    <img src={lumetuff} alt='' className='w-full h-full ' />

                </div>


                <div className=' md:w-1/2 md:mt-0 mt-[20px] flex flex-col gap-[20px]   '>

                    <div data-aos="fade-top" data-aos-duration='1000' className='md:block hidden'>
                        <p className='text-[55px] font-roboto font-extrabold '>APL Apollo Lume Tuff</p>
                        <i className='text-[25px] text-[#4E4E4E] font-roboto font-semibold'>Made to last</i>
                    </div>

                    <div data-aos="fade-down" data-aos-duration='1000'>
                        <p className=' text-[#4E4E4E] md:text-[17px] text-[17px] font-roboto'>
                            <span className='text-[#F47E32] font-roboto font-semibold tracking-wide'>LUME TUFF 55% ZINC-ALUMINIUM ALLOY COATED COILS</span>
                            Lume Tuff is a high-performing option for environments that frequently experience high
                            temperatures, high amounts of precipitation, and marine environments. Zinc and
                            Aluminum-coated metal roofs offer better surface protection than galvanized   roofs however,
                            They're more vulnerable to scratches, blemishes, and cut edges.
                        </p>


                        <p className=' text-[#4E4E4E] md:text-[17px] text-[17px] font-roboto mt-2 tracking-wide '>
                            Lume-tuff metal roofs, coated with zinc and aluminum, provide excellent protection in
                            high-temperature, high-precipitation, and marine environments. They offer superior surfaces.
                            protection compared to galvanized roofs, with high corrosion resistance,   flatness, and paint suitability.
                            suitability. These versatile and durable sheets are widely    used for roofing,building panels, walls, ducts, and more.
                        </p>


                        <p className=' text-[#4E4E4E] md:text-[17px] text-[17px] font-roboto mt-2 tracking-wide'>
                            Due to its versatility, durability, and beautiful appearance, zinc and aluminum coated
                            Steel is favored broadly. A high level of corrosion resistance, superior flatness, and an
                            excellent surface suitable for painting. are a few attributes that characterize our zinc and
                            Aluminum-coated product. This product can be used in a wide variety of applications.
                            including roofing, building panels (both painted and bare), wall panels, computer cases,
                            furnace parts, drain pans, microwave oven frames, duct work, etc.
                        </p>



                    </div>



                </div>

            </div >




            {/* Advantage */}


            <div className='md:mt-[100px] mt-[20px]'>

                {/* Available range */}

                <div className='md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px] mt-[50px]'>
                    <p className='md:text-[55px] text-[20px] md:text-left text-center text-[#4E4E4E] font-roboto font-semibold border-b border-[#FE6910]'>AVAILABLE  <spnan className='text-[#FE6910] '> RANGE</spnan></p>
                    <p className=' md:block hidden md:text-[40px] text-[20px] text-[#4E4E4E] font-roboto text-end font-semibold mt-[50px]'>LUME TUFF  <spnan className='text-[#FE6910] '>PRO</spnan></p>

                    <div className='flex md:flex-row flex-col justify-between  md:gap-[100px] gap-[30px] ' >

                        <div className='mt-[20px] space-y-2  md:w-1/2 w-full'>

                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Thickness Range</p>
                                <p>0.20 to 1.20mm</p>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Width Range</p>
                                <p> 900mm to 1440mm</p>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Quality standards</p>

                                <p>ASTM A 792 M, AS 1397_2011, IS 15961</p>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Metallic Coating</p>

                                <p>AZ-70</p>
                            </div>

                        </div>

                        <p className=' md:hidden block md:text-[40px] text-center text-[20px] text-[#4E4E4E] font-roboto  border-b border-[#FE6910]  font-semibold md:mt-[50px]'>LUME TUFF  <spnan className='text-[#FE6910] '>PRO</spnan></p>


                        <div className='md:mt-[20px] space-y-2  md:w-1/2 w-full'>

                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]   p-2'>
                                <p>Thickness Range</p>
                                <p>0.20 to 1.20mm</p>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Width Range</p>
                                <p>900mm to 1440mm</p>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Quality standards</p>

                                <p>ASTM A 792 M, AS 1397_2011, IS 15961</p>
                            </div>
                            <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                                <p>Metallic Coating</p>

                                <p>AZ-150 </p>
                            </div>

                        </div>

                    </div>

                </div>


            </div>




            <section className='flex md:flex-row flex-col justify-between items-center md:gap-[100px] gap-[20px] md:mt-[100px] mt-[20px] md:pl-[50px] pl-[10px] md:pr-[50px] pr-[10px]'>


                <div className='md:w-[50%] md:block hidden w-full'>
                    <p className='md:text-[55px] text-[25px] mt-[20px] text-[#F47E32] font-roboto font-bold text-center'>ADVANTAGE</p>

                    <div className=' flex  justify-between gap-2 md:mt-[50px] mt-[20px]'>

                        <div className='flex flex-col items-center gap-2 w-1/2 '>
                            <img src={icon1} alt="apl apollo" className='md:w-[100px] w-[200px]' />
                            <p className='md:text-[17px] text-[20px] text-center text-[#4E4E4E] font-roboto font-medium '>Acts as a primer for powder coating</p>
                        </div>

                        <div className='flex flex-col items-center gap-2 w-1/2 '>
                            <img src={icon2} alt='' className='md:w-[100px] w-[200px]' />
                            <p className='md:text-[17px] text-[20px] text-center text-[#4E4E4E] font-roboto font-medium '> Practically eliminating the risk of
                                black corrosion products during the stocking period </p>
                        </div>

                    </div>

                    <div className='  flex  justify-between gap-2 '>

                        <div className='flex flex-col items-center gap-2  w-1/2 mt-2 '>
                            <img src={icon3} alt='' className='md:w-[100px] w-[200px]' />
                            <p className='md:text-[17px] text-[20px] text-center text-[#4E4E4E] font-roboto font-medium '>Acts as a dry lubricant for
                                cold profiling/drawing</p>
                        </div>

                        <div className='flex flex-col items-center gap-2  w-1/2 mt-2'>
                            <img src={icon4} alt='' className='md:w-[100px] w-[200px]' />
                            <p className='md:text-[17px] text-[20px] text-center text-[#4E4E4E] font-roboto font-medium '>
                                Imparts anti-finger properties


                                during handling and usage
                            </p>
                        </div>

                    </div>

                </div>

                <div className='md:w-[50%] md:hidden block w-full'>

                    <p className='md:text-[55px] text-[25px] mt-[20px] text-[#F47E32] font-roboto font-bold text-center'>ADVANTAGE</p>

                    <Swiper
                        pagination={true}
                        autoplay={{
                            delay: 2000
                        }}
                        modules={[Pagination, Autoplay]}
                    >

                        <SwiperSlide>
                            <div className='flex flex-col items-center gap-2  '>
                                <img src={icon1} alt="apl apollo" className='md:w-[100px] w-[150px]' />
                                <p className='md:text-[17px] text-[17px] text-center text-[#4E4E4E] font-roboto font-medium '>Acts as a primer for powder coating</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='flex flex-col items-center gap-2 '>
                                <img src={icon2} alt='' className='md:w-[100px] w-[150px]' />
                                <p className='md:text-[17px] text-[17px] text-center text-[#4E4E4E] font-roboto font-medium '> Practically eliminating the risk of
                                    black corrosion products during the stocking period </p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center gap-2 mt-2 '>
                                <img src={icon3} alt='' className='md:w-[100px] w-[150px]' />
                                <p className='md:text-[17px] text-[17px] text-center text-[#4E4E4E] font-roboto font-medium '>Acts as a dry lubricant for
                                    cold profiling/drawing</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='flex flex-col items-center gap-2   mt-2'>
                                <img src={icon4} alt='' className='md:w-[100px] w-[150px]' />
                                <p className='md:text-[17px] text-[17px] text-center text-[#4E4E4E] font-roboto font-medium '>
                                    Imparts anti-finger properties
                                    during handling and usage
                                </p>
                            </div>
                        </SwiperSlide>


                    </Swiper>

                </div>


                {/* Features  */}

                <div className='md:w-[50%] w-full'>
                    <div>
                        <p className='md:text-[55px] text-[25px]   text-center font-roboto font-semibold text-[#F47E32]'>FEATURES</p>
                    </div>


                    <div className='md:mt-[50px] mt-[20px] space-y-[12.5px]  '>

                        <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                            <img src={icon5} alt='' />
                            <p>High Resistance to Abrasion</p>
                        </div>

                        <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                            <img src={icon6} alt='' />
                            <p>Excellent Heat Reflectivity</p>
                        </div>

                        <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                            <img src={icon7} alt='' />
                            <p>Better heat (oxidation) resistance</p>
                        </div>

                        <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                            <img src={icon8} alt='' />
                            <p>Better heat insulation</p>
                        </div>

                        <div className='flex justify-between md:text-[19px] text-[15px] font-roboto font-medium text-[#4E4E4E] rounded-2xl bg-[#7A7A7A]/10 pl-[20px]  p-2'>
                            <img src={icon9} alt='' />
                            <p>Superior electrical conductivity</p>
                        </div>

                    </div>


                </div>


            </section >











            {/* Mark of ignuity */}

            <div className='md:mt-[100px] mt-[40px] flex md:flex-row flex-col items-center md:ml-[50px] ml-[10px] md:mr-[50px] mr-[10px] bg-gradient-to-b from-[#005C61]/60 to-[#000000] p-10' >
                <div>
                    <p className='md:text-[70px] text-[35px] md:text-start text-center text-white font-roboto font-semibold'>MARK OF <span className='text-[#F47E32]'> INGENUITY</span></p>
                </div>

                <div className='md:w-[800px] w-full md:h-[400px] h-full'>
                    <img src={ignuity} alt='' className='w-full h-full' />
                </div>

            </div>



            {/* Product Application carousoles */}

            {/* Owl Crousol */}
            <div className='md:block hidden'>
                <div className='mt-[80px]'>
                    <p className='text-[#000000] md:text-[55px] text-[25px] font-roboto text-center font-extrabold'>
                        PRODUCT <span className='text-[#F47E32]'> APPLICATIONS</span>
                    </p>
                </div>


                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={60}
                    slidesPerView={4}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className='w-[1410px]  h-fit  mt-[76px] bg-gradient-to-b from-[#005C61]/60 to-[#000000]'

                >

                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa5} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa6} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa7} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa5} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa6} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa7} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px] p-2'>
                            <img src={pa6} alt='' className='w-full h-full rounded shadow-[#00000040]' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* for  mobile responsive */}

            <div className='md:hidden block pl-[10px] pr-[10px]'>
                <div className='mt-[47px]'>
                    <p className='font-roboto md:text-[50px] text-[25px] font-extrabold'>
                        PRODUCT APPLICATIONS
                    </p>
                </div>


                <Swiper
                    modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                    spaceBetween={10}
                    slidesPerView={3}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        type: 'fraction',
                    }}

                    scrollbar={{ draggable: true }}
                    className='max-w-[1400px] h-fit  mt-[32px]'

                >

                    <div className='flex justify-center items-center pl-[50px] pr-[50px]'>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa7} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa5} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa7} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa6} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                        <SwiperSlide className='w-[502px] h-[496px]'>
                            <img src={pa4} alt='' className='w-full h-full rounded' />
                        </SwiperSlide>

                    </div>

                </Swiper>
            </div>

            {/* Structure careousel */}
            <div className='flex md:flex-row flex-col justify-between md:mt-[100px] my-[50px]'>

                <div className='flex flex-col md:pl-[50px] pl-[10px]  gap-[10px]'>

                    <p className='font-roboto md:text-[50px] text-[25px] font-extrabold'>STRUCTURAL IN OUR WORLD</p>

                    <div className=''>
                        <button
                            className='text-[#F47E32]  flex justify-center font-roboto text-[15px] hover:bg-gray-900 hover:text-white duration-300 
        font-semibold items-center gap-[10px] w-[219px] h-[56px] border-2 border-[#F47E32] hover:border-white'>
                            <span>DOWNLOAD BROCHURE </span><BsBoxArrowInUpRight size={20} /> </button>
                    </div>
                </div>



                <div className='md:mt-[2px] mt-[40px] md:pl-[0px] pl-[10px]  md:pr-0 pr-[10px]'>
                    <Swiper
                        slidesPerView={2}
                        centeredSlides={true}
                        spaceBetween={30}
                        scrollbar={{ draggable: true }}
                        pagination={{
                            type: 'fraction',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination, Autoplay, Navigation, Scrollbar]}
                        className='md:w-[900px] w-full md:h-[350px] h-fit'
                    >
                        <div >

                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={swa2} alt='' />
                            </SwiperSlide>



                        </div>
                    </Swiper>

                </div>

            </div>



            <Footer />

        </>
    )
}

export default Product2;