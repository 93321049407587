import React from 'react'
import { BsDownload } from 'react-icons/bs';
import Navbar from '../../homecomponent/Navbar';
import banner from '../../../assest/contactImage/banner2.webp';
import Footer from '../../homecomponent/Footer';
import { FaFilePdf } from "react-icons/fa6";


const AnnualReport = () => {
    return (

        <>


            <div className='max-w-[1920px] md:h-[620px] h-[400px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[110px] mt-[50px] gap-[16px]'>
                            <div className='pb-[30px] font-black  text-[70px] text-white text-center uppercase'>Annual Report
                            </div>

                            {/* <h1 className='md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto  text-center'>Coming soon </h1> */}
                            <div className='w-[176px] h-[2px] bg-white '></div>

                        </div>

                    </div>
                </div>

            </div>


            <div className='flex flex-col mt-[50px] items-center md:gap-[33px] gap-[10px]'>

                <div className='border-b-2 border-gray-300 md:w-[800px] w-full md:h-[35px] h-[56px]  '>
                    <h1 className='text-[#3D3D3D] md:text-[15px] text-[15px] font-bold font-roboto uppercase'>Financial Year 2023</h1>
                </div>

                <div className=' md:w-[800px] w-full md:h-[30px] h-[56px]   flex  items-center  gap-[100px] p-1'>
                    <div className='flex items-center gap-[20px]'>
                        <FaFilePdf size={20} />
                        <h1 className='md:text-[14px] text-[#00ACB6] text-[14px] font-bold font-roboto'><a href=''></a></h1>
                    </div>


                </div>
            </div>

            <Footer />
        </>


    )
}

export default AnnualReport