import React from 'react'
import banner from '../assest/contactImage/banner2.webp';
import Navbar from './homecomponent/Navbar';
import Footer from './homecomponent/Footer';

const DeetailOf_DT = () => {
    return (
        <>

            <div className='max-w-[1920px] md:h-[620px] h-[300px]'>
                <div style={{ backgroundImage: `url(${banner})` }}
                    className='w-full h-full bg-cover object-fill '
                >
                    <div className='bg-[#0A0A0A]/60 h-full relative'>
                        <Navbar />


                        {/* Text Area */}


                        <div className='flex flex-col justify-center items-center md:mt-[110px] mt-[50px] gap-[16px]'>
                            <div className='pb-[30px] font-black  md:text-[70px] text-white text-center uppercase'>Details of Debenture Trustee
                            </div>

                            {/* <h1 className='md:text-[24px] text-[17px] text-[#FFFFFF] font-semibold font-roboto  text-center'>Coming soon </h1> */}
                            <div className='w-[176px] h-[2px] bg-white '></div>

                        </div>

                    </div>
                </div>

            </div>


            <div className='flex justify-center items-center mt-[50px]'>
                <div className='md:w-[550px] w-full md:h-[400px] h-[fit] bg-white rounded-[10px] shadow-inner shadow-gray-500 p-5'>
                    <h1 className='text-[25px] text-black font-roboto font-semibold text-center w-full border-b-2 border-gray-500 '> Axis Trustee Services Limited</h1>

                    <div className='flex mt-[30px] justify-between md:mr-[180px] text-[18px] text-gray-800 font-roboto'>
                        <h1>SEBI Registration No : </h1>
                        <h1>IND000000494</h1>
                    </div>

                    <div className='flex mt-[5px] justify-between md:mr-[150px] text-gray-800 text-[18px] font-roboto'>
                        <h1>Contact Person : </h1>
                        <h1>Mr. Rajendar Singh</h1>
                    </div>

                    <div className=' flex mt-[5px] justify-between md:mr-[50px]  text-[18px] font-roboto text-gray-800'>
                        <h1 className=''>Registered Office and<br className='md:block hidden' /> Corporate Office: </h1>
                        <h1 className=''>Plot 25, IInd Floor, Pusa Road<br /> Karol Bagh, New Delhi - 110005</h1>
                    </div>

                    <div className=' flex mt-[5px] justify-between md:mr-[200px]  text-[18px] font-roboto text-gray-800'>
                        <h1 className=''>Tel no :  </h1>
                        <h1 className=''>9716109404</h1>
                    </div>

                    <div className=' flex mt-[5px] justify-between md:mr-[185px]  text-[18px] font-roboto text-gray-800'>
                        <h1 className=''>Fax :  </h1>
                        <h1 className=''>022 42026695</h1>
                    </div>

                    <div className=' flex mt-[5px] justify-between md:mr-[150px]  text-[18px] font-roboto text-gray-800'>
                        <h1 className=''>Website :  </h1>
                        <a href='https://www.axistrustee.in/' target='blank' className=' text-blue-600'>www.axistrustee.in</a>
                    </div>

                </div>
            </div>



            <Footer />
        </>
    )
}

export default DeetailOf_DT