import React from 'react'
import { useSwiper } from 'swiper/react';
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";


const NavButtons = () => {

    const swiper = useSwiper();



    return (
        <>
            <div className=' flex gap-6'>
                <button className=' w-12 h-12 rounded-full text-white border border-white flex justify-center items-center' onClick={() => swiper.slidePrev()}> <FaArrowLeft size={20} /> </button>
                <button className='w-12 h-12 rounded-full text-white border border-white flex justify-center items-center' onClick={() => swiper.slideNext()}>  <FaArrowRight size={20} /> </button>
            </div>

        </>
    )
}

export default NavButtons;